import styled from "styled-components";
import { motion } from "framer-motion";
const PolicyStyle = styled.ul`
  .scroll {
    position: relative;
  }
`;
const PolicyMainStyle = styled.div`
  .banner-image {
    display: flex;
    .banner-text {
      margin: auto;
      background: #2d59a2;
      color: #fff;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 40px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const PolicyMain = ({ t }: any) => {
  return (
    <PolicyMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("policyTitle")}
          </motion.h1>
        </motion.div>
      </div>
    </PolicyMainStyle>
  );
};
const PolicyContentsStyle = styled.div`
  max-width: 1200px;
  width: 95%;
  margin: 50px auto;
  p {
    margin: 20px 0 5px;
    font-weight: bold;
  }
  span {
    white-space: pre-line;
  }
`;
const PolicyContents = ({ t }: any) => {
  return (
    <PolicyContentsStyle>
      <span>
        {`에스씨지솔루션즈(주)(이하 ‘회사’)는 고객님의 개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 준수하고 있습니다. 회사는 개인정보취급방침을 정하여 고객님이 제공한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 웹사이트(또는 개별공지)를 통하여 공지할 것입니다.`}
      </span>
      <div style={{ fontSize: "16px", marginTop: "40px" }}>
        <p>수집하는 개인정보 항목</p>
        <span>
          {`회사는 고객 문의에 대한 답변을 위하여 아래와 같은 개인정보를 수집하고 있습니다.
1) 수집항목 : 회사명, 이름, 직급, 이메일, 전화번호(일반전화, 휴대전화)
2) 개인정보 수집방법 : 전화문의 및 이메일 문의`}
        </span>

        <p>개인정보의 수집목적 및 이용목적</p>
        <span>
          {`개인정보 수집 및 이용목적은 이용자의 질의에 대하여 대응하고 원활한 서비스 지원을 하는데 있습니다.`}
        </span>

        <p>개인정보의 보유 및 이용기간</p>
        <span>
          {`회사는 개인정보 수집 및 이용목적(문의에 대한 답변 등)이 달성될 때까지만 해당정보를 보유합니다.`}
        </span>

        <p>개인정보의 파기에 관한 사항</p>
        <span>
          {`회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`}
        </span>

        <p>수집한 개인정보의 제 3자 제공</p>
        <span>
          {`회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
1) 이용자들이 사전에 동의한 경우
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`}
        </span>

        <p>정보주체의 권리 · 의무 및 행사방법</p>
        <span>
          {`정보주체자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. 이용자들의 개인정보 처리 요구사항은 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
1) 개인정보 열람요구
2) 오류 등이 있을 경우 정정 요구
3) 삭제요구
4) 처리정지 요구`}
        </span>

        <p>개인정보 자동수집 장치의 설치, 운영</p>
        <span>
          {`회사는 쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.`}
        </span>

        <p>개인정보에 관한 민원서비스</p>
        <span>
          {`회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.`}
        </span>

        <p>개인정보 관리책임자</p>
        <span>
          {`관리책임자: 이소연
전화번호: 02-872-5363
이메일주소: soyeon@scgs.co.kr

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.


기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
개인분쟁조정위원회 (http://www.1336.or.kr / 1336)
정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02-580-0533~4)
대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr / 02-3480-3600)
경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 02-392-0330)`}
        </span>
      </div>
    </PolicyContentsStyle>
  );
};

const Policy = ({ t }: any) => {
  return (
    <PolicyStyle className="no-select">
      <PolicyMain t={t} />
      <PolicyContents t={t} />
    </PolicyStyle>
  );
};

export default Policy;
