import styled from "styled-components";
import { motion } from "framer-motion";
import API from "../api/api";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Icons from "../assets/icons";
const NewsBoardDetailStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
`;

const NewsBoardDetailMainStyle = styled.div`
  .board-head {
    width: 100%;
    display: flex;
    position: relative;
    background: #2d59a2;
    h1 {
      color: #fff;
      padding: 50px 0;
      font-size: 40px;
      line-height: 1em;
      margin: auto;
    }
  }
  .board-back {
    display: flex;
    align-items: baseline;
    position: absolute;
    line-height: 1em;
    padding: 5px 10px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 3px;
    color: #2d59a2;
    font-weight: bold;
    font-size: 18px;
    svg path {
      transition: inherit;
      fill: #2d59a2;
    }
    &.bottom-back {
      right: 3px;
      bottom: 20px;
    }
    &:hover {
      transition: 0.1s;
      background: #eee;
    }
    &:active {
      transition: 0.1s;
      background: #ddd;
      transform: translate(1px, 1px);
    }
  }
  .board {
    display: flex;
    background: #ffffff99;
    color: #000;
    width: 100%;
    .board-box {
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;
      position: relative;

      table {
        width: 100%;
        & * {
          max-width: 100%;
          line-break: anywhere;
        }
        tr {
          border-bottom: 1px solid #ddd;
          th {
            padding: 20px;
            & + td {
              border-left: 1px solid #ddd;
            }
          }
          td {
            padding: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .board {
      padding: unset;
      .board-box {
        margin: 0px auto;
        .board-head {
          margin: 50px auto 0;
          h1 {
          }
        }
      }
      .board-back {
        padding: 3px 10px;
        &.bottom-back {
          bottom: 2px;
        }
      }
    }
  }
`;
const NewsBoardDetailMain = ({ t }: any) => {
  const location = useLocation();
  const path2 = location.pathname.split("/")[2];
  const path3 = location.pathname.split("/")[3];
  const [data, setData] = useState<any>();
  const [checkPath, setCheckPath] = useState("");

  useEffect(() => {
    path2 === "news" && setCheckPath("Board_News");
    path2 === "sns" && setCheckPath("");
    path2 === "disclose" && setCheckPath("Board_Announcement");

    (checkPath === "Board_News" || checkPath === "Board_Announcement") &&
      API.BOARD_DETAIL(setData, Number(path3), String(checkPath));
  }, [path2, path3, checkPath]);

  return (
    <NewsBoardDetailMainStyle>
      <motion.div
        initial={{ translateY: "-50px", opacity: 0 }}
        animate={{ translateY: "0", opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 1 }}
        className="board-head"
      >
        <h1>{t("newsPost")}</h1>
      </motion.div>
      <div className="board scroll">
        <div className="board-box ql-editor">
          {data && (
            <motion.table
              initial={{ translateY: "-50px", opacity: 0 }}
              animate={{ translateY: "0", opacity: 1 }}
              transition={{ delay: 0.2, ease: "easeInOut", duration: 0.7 }}
              style={{ marginBottom: "50px" }}
            >
              <tbody>
                <tr>
                  <th style={{ fontSize: "24px" }} colSpan={2}>
                    {data.title}
                  </th>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    dangerouslySetInnerHTML={{ __html: data.content }}
                    style={{ fontSize: "16px" }}
                  ></td>
                </tr>
              </tbody>
            </motion.table>
          )}
          <Link
            to={{
              pathname: `/news`,
            }}
            state={{
              type: path2,
            }}
            className="board-back bottom-back"
          >
            <Icons.IconArrowLeft />
            <p>back</p>
          </Link>
        </div>
      </div>
    </NewsBoardDetailMainStyle>
  );
};

const NewsBoardDetail = ({ t }: any) => {
  return (
    <NewsBoardDetailStyle className="no-select">
      <NewsBoardDetailMain t={t} />
    </NewsBoardDetailStyle>
  );
};

export default NewsBoardDetail;
