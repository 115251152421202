export default function RatioCheck() {
  const targets = window.document.querySelectorAll(".check-ratio");
  for (var i = 0; targets.length > i; ++i) {
    const targetBox = targets[i];
    if (targetBox instanceof HTMLElement) {
      // 가로세로 비율 계산
      const divAspect = targetBox.offsetHeight / targetBox.offsetWidth;

      const targetElement = targetBox.querySelector("video");
      // setTimeout(() => {
      if (targetElement !== null) {
        const imgAspect = targetElement.videoHeight / targetElement.videoWidth;
        if (imgAspect <= divAspect) {
          // 대상이 div보다 납작한 경우 세로를 div에 맞추고 가로는 잘라낸다
          targetElement.style.cssText = "width: auto; height: 100%;";
        } else {
          // 대상이 div보다 길쭉한 경우 가로를 div에 맞추고 세로를 잘라낸다
          targetElement.style.cssText = "width: 100%; height: auto;";
        }
      }
      // }, 500);
    }
  }
}
