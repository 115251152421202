import image1 from "./images/checkpoint_202307_01.jpg";
import image2 from "./images/checkpoint_202307_02.jpg";

const Index = () => {
  return (
    <div>
      <table
        id="Table_01"
        width="750"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
      >
        <thead />
        <tbody>
          <tr>
            <td align="right" valign="middle" style={{ padding: "5px" }}>
              <p>
                본 메일이 보이지 않는 경우
                <a
                  href="https://www.scgs.co.kr/edm/checkpoint/index.html"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5694d9" }}
                >
                  이곳
                </a>
                을 눌러 주십시오.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={image1}
                alt="img"
                width={750}
                useMap="#Map"
                border="0"
              />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image2} alt="" width={750} border="0" />
            </td>
          </tr>
        </tbody>
        <tfoot />
      </table>
      <map name="Map">
        <area
          shape="rect"
          coords="47,1309,356,1734"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdvXXnwyworNBqJCAHYYTfRbukmK2YBtRnoG95xdrIl6Z9Gow/viewform?usp=sf_link"
          target="_blank"
        />
        <area
          shape="rect"
          coords="390,1309,701,1734"
          href="https://docs.google.com/forms/d/e/1FAIpQLSejuwLLivFqznfYApc5-1OqyiROkaKtw3WYKGISIC4VaNTUgA/viewform?usp=sf_link"
          target="_blank"
        />
      </map>
    </div>
  );
};
export default Index;
