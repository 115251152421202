import { useState, useEffect } from "react";
import En202303 from "./202303/index";
import En202307 from "./202307/index";

import "./edmen.css";

const GetPageComponent = () => {
  useEffect(() => {
    setType(window.location.pathname.split("/")[3]);
    setType2(window.location.pathname.split("/")[4]);
  }, [window.location.pathname]);
  const [type, setType] = useState(window.location.pathname.split("/")[3]);
  const [type2, setType2] = useState(window.location.pathname.split("/")[4]);
  console.log(type, type2);
  return (
    <div className="edm">
      {type2 === "index.html" && (
        <>
          {type === "202303" && <En202303 />}
          {type === "202307" && <En202307 />}
        </>
      )}
    </div>
  );
};
const EDMENPage = () => {
  return <GetPageComponent />;
};

export default EDMENPage;
