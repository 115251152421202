import image1 from "./images/BenQ-edm_v1_01.jpg";
import image2 from "./images/BenQ-edm_v1_02.jpg";

const Index = () => {
  return (
    <div>
      <table
        id="Table_01"
        width="750"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
      >
        <thead />
        <tbody>
          <tr>
            <td align="right" valign="middle" style={{ padding: "5px" }}>
              <p>
                본 메일이 보이지 않는 경우
                <a
                  href="https://www.scgs.co.kr/edm/benq0720/index.html"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5694d9" }}
                >
                  이곳
                </a>
                을 눌러 주십시오.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={image1}
                alt="img"
                width={750}
                border="0"
              />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image2} alt="" width={750} useMap="#Map" border="0" />
            </td>
          </tr>
        </tbody>
        <tfoot />
      </table>
      <map name="Map">
        <area
          shape="rect"
          coords="0,1665,257,1745"
          href="https://www.scgs.co.kr"
          target="_blank"
        />
        <area
          shape="rect"
          coords="257,1665,750,1745"
          href="mailto:jnkim@scgs.co.kr"
          target="_blank"
        />
      </map>
    </div>
  );
};
export default Index;
