import image1 from "./images/FY24Q3-Partner-Program_0821_01.jpg";
import image2 from "./images/FY24Q3-Partner-Program_0821_02.jpg";
import image3 from "./images/FY24Q3-Partner-Program_0821_03.jpg";
import image4 from "./images/FY24Q3-Partner-Program_0821_04.jpg";
import image5 from "./images/FY24Q3-Partner-Program_0821_05.jpg";

const Index = () => {
  return (
    <div>
      <table
        id="Table_01"
        width="750"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
      >
        <thead />
        <tbody>
          <tr>
            <td align="right" valign="middle" style={{ padding: "5px" }}>
              <p>
                본 메일이 보이지 않는 경우
                <a
                  href="https://www.scgs.co.kr/edm/24Q3SPIFF/index_0821.html"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5694d9" }}
                >
                  이곳
                </a>
                을 눌러 주십시오.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img src={image1} alt="" width="750" useMap="#Map" border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image2} alt="" width="750" border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image3} alt="" width="750"  border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image4} alt="" width="750" usemap="#Map2" border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image5} alt="" width="750" usemap="#Map3" border="0" />
            </td>
          </tr>
        </tbody>
        <tfoot />
      </table>

      <map name="Map">
        <area
          shape="rect" 
          coords="248,306,511,346" 
          href="https://app.modusign.co.kr/link/13b7d660-37fe-11ee-98a9-c9c0b0ba8678/authentication?shareType=URL" 
          target="_blank"
        />
      </map>
      <map name="Map2">
        <area
          shape="rect"
          coords="375,277,665,314"
          href="https://scgs-img.s3.ap-northeast-2.amazonaws.com/edm/FY24Q3e-catalogue-sp.pdf" 
          target="_blank"
        />
      </map>
      <map name="Map3">
        <area
          shape="rect"
          coords="391,140,677,180"
          href="mailto:marketing@scgs.co.kr"
        />
      </map>
    </div>
  );
};
export default Index;
