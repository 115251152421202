import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import API from "../api/api";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

interface SendDataType {
  e?: any;
  key?: any;
  setSendData?: any;
  sendData?: any;
  setHtmlData?: any;
  htmlData?: {};
  inquireType?: string;
  receiver_id?: number;
  html?: any;
  text?: any;
  type_id?: any;
  productType_id?: any;
  status_id?: any;
  subject?: string;
}

const MemberPostStyle = styled.div`
  .post-policy {
    max-width: 1200px;
    width: 95%;
    margin: 50px auto;
    .policy-title {
      display: flex;
      font-size: 16px;
      margin-bottom: 10px;
      .in {
        font-size: 13px;
        border: 1px solid #2d59a2;
        transition: 0.3s;
        position: relative;
        min-width: 50px;
        display: flex;
        height: fit-content;
        margin: auto 10px;
        border-radius: 5px;
        span {
          margin: auto;
          position: relative;
          z-index: 1;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        ::after {
          transition: inherit;
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          background-color: #2d59a2;
          top: 0;
          left: 0;
          z-index: 0;
        }
        :hover {
          color: #fff;
          ::after {
            width: 100%;
          }
          p {
          }
        }
        p {
          position: relative;
          transition: inherit;
          margin: auto;
          z-index: 1;
        }
      }
    }
    textarea {
      font-family: inherit;
      padding: 10px;
      text-indent: 0;
      border: 1px solid #ddd;
      border-radius: 3px;
      width: 100%;
      font-size: 16px;
      &:focus-visible {
        outline: none;
      }
    }
    .policy-check-box {
      display: flex;
      font-size: 16px;
      input {
        margin-right: 5px;
      }
    }
  }
  .modal-box {
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 95%;
    margin: 50px auto;
    .modal-text {
      .modal-button {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        margin-top: 20px;
        box-sizing: border-box;
        span {
          margin-left: auto;
          transition: 0.3s;
          padding: 5px 30px;
          background: #2d59a2;
          color: #fff;
          &:hover {
            background: #2d59a2;
          }
        }
      }
    }
    p {
      margin: auto 0;
    }
  }
  .header-style {
    display: flex;
    margin-bottom: 10px;
    color: #2d59a2;
    ::before {
      /* 구분자 숨김 240809 */
      /* content: ""; */
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
  }
  @media (max-width: 768px) {
    .post-policy {
      margin: 20px auto;
      textarea {
        padding: 5px;
        font-size: 14px;
      }
      .policy-title {
        display: unset;
        font-size: 14px;
        display: block;
        .in {
          margin: 10px auto;
        }
      }
      .policy-check-box {
        font-size: 14px;
      }
    }
    .modal-box {
      width: 95%;
      padding: 10px;
      margin: 20px auto;
      .modal-text {
        padding: unset;
      }
    }
    .header-style {
      font-size: 18px;
    }
  }
`;
const SitemapMainStyle = styled.div`
  .banner-image {
    background-size: cover;
    background-position: center;
    display: flex;
    .banner-text {
      margin: auto;
      background: #2d59a2;
      color: #fff;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 40px;
        line-height: 1em;
        margin: auto;
      }
      p {
        max-width: 720px;
        margin: 30px auto auto;
      }
    }
  }
  @media (max-width: 768px) {
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const SiteMapMain = ({ t }: any) => {
  return (
    <SitemapMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("footerPost")}
          </motion.h1>
        </motion.div>
      </div>
    </SitemapMainStyle>
  );
};
const MemberPostModal = styled.div`
  h1 {
    font-size: 50px;
    margin-bottom: 50px;
  }
  ul {
    width: 100%;
    font-size: 18px;
    li {
      display: flex;
      margin-bottom: 2px;
      &.cs-contents {
        flex-direction: column;
        .cs-title {
          margin-bottom: 10px;
        }
      }
      .cs-title {
        background: #2d59a2;
        color: #fff;
        min-width: 150px;
        font-weight: bold;
        display: flex;
        p {
          margin: auto;
        }
      }
      .cs-input {
        padding-left: 10px;
        width: 100%;
        .cs-type {
          display: flex;
          height: 100%;
          input {
            margin-right: 5px;
            & + input {
              margin-left: 25px;
            }
            width: fit-content;
          }
        }
      }
      .cs-product {
        display: flex;
        li {
          input {
            margin-right: 5px;
            width: unset;
          }
          & + li {
            margin-top: unset;
            margin-left: 20px;
          }
        }
      }
    }
  }
  select,
  textarea,
  input {
    padding: 8px 5px;
    text-indent: 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
  }
  @media (max-width: 768px) {
    h1 {
      margin-bottom: 20px;
    }
    ul {
      width: unset;
      li {
        flex-direction: column;
        & + li {
          margin-top: 20px;
        }
        .cs-title {
          height: 36px;
          font-size: 14px;
        }
        .cs-input {
          padding-left: unset;
          textarea {
            padding: 5px;
            height: fit-content;
          }
          .cs-type {
            display: flex;
            height: 100%;
            input {
              margin-right: 5px;
              & + input {
                margin-left: 25px;
              }
              width: fit-content;
            }
          }
        }
      }
    }
    select,
    textarea,
    input {
      padding: 0 5px;
      height: 36px;
      font-size: 14px;
    }
  }
`;
const MailSend = ({ i18n }: any, sendData: any, htmlData: any) => {
  let a = Object.entries(window.document.querySelectorAll("input"));
  let b = Object.entries(window.document.querySelectorAll("textarea"));
  try {
    [...a, ...b].forEach((e) => {
      if (e[1].value === "") {
        throw new Error("stop loop");
      }
    });
  } catch (e) {
    return alert(
      i18n.language === "ko"
        ? "모든 값을 입력해 주세요"
        : "Please enter all values"
    );
  }
  API.MAIL_SEND(sendData, htmlData, i18n);
};
const htmlChange = ({ e, key, setHtmlData, htmlData }: SendDataType) => {
  // input 값 변경시 key 맞춰서 값 변경
  setHtmlData({
    ...htmlData,
    [key]: e.target.value,
  });
};
const titleChange = ({
  e,
  key,
  setSendData,
  sendData,
  inquireType,
}: SendDataType) => {
  // 제목 값 변경시 select에 맞춰서 값 변경
  switch (inquireType) {
    case "information":
      setSendData({
        ...sendData,
        [`subject`]: `소리함 - 제보 : ${e.target.value}`,
      });
      break;
    case "suggest":
      setSendData({
        ...sendData,
        [`subject`]: `소리함 - 건의 : ${e.target.value}`,
      });
      break;
    case "inquireOther":
      setSendData({
        ...sendData,
        [`subject`]: `기타 문의 : ${e.target.value}`,
      });
      break;
  }
};
const MemberPost = () => {
  const { t, i18n } = useTranslation();
  const [htmlData, setHtmlData] = useState({
    company: "",
    manager: "",
    email: "",
    phoneNumber: "",
    contents: "",
  });
  const [html, setHtml] = useState(
    <div
      dangerouslySetInnerHTML={{
        __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
      }}
    ></div>
  );
  const [sendData, setSendData] = useState<SendDataType>({
    receiver_id: 1,
    subject: "",
    text: "",
    html: html,
    type_id: 1,
    productType_id: null,
  });
  const [inquireType, setInquireType] = useState("information");
  const [isAgree, setIsAgree] = useState(false);
  useEffect(() => {
    switch (inquireType) {
      case "information":
        setSendData({
          ...sendData,
          receiver_id: 1,
          ["type_id"]: 1,
          subject: `소리함 - 제보 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "suggest":
        setSendData({
          ...sendData,
          receiver_id: 1,
          ["type_id"]: 2,
          subject: `소리함 - 건의 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "inquireOther":
        setSendData({
          ...sendData,
          receiver_id: 1,
          ["type_id"]: 3,
          subject: `기타 문의 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      default:
        break;
    }
  }, [inquireType]);

  useEffect(() => {
    // 전송할 html 데이터 설정
    setHtml(
      <div
        dangerouslySetInnerHTML={{
          __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
        }}
      ></div>
    );
    setSendData({
      ...sendData,
      html: html,
    });
  }, [htmlData]);

  return (
    <>
      <MemberPostStyle>
        <SiteMapMain t={t} />
        <div className="post-policy">
          <h1 className="header-style">개인정보 수집 및 이용에 대한 안내</h1>
          <textarea
            disabled
            rows={5}
            defaultValue={`개인정보를 제공받는 자 : 에스씨지솔루션즈(주)
개인정보를 제공받는 자의 개인정보 이용 목적 : 소리함(건의함/제보함/기타 문의)
제공하는 개인정보 항목 : 이름, 연락처, 쿠키 등
개인정보를 제공받는 자의 개인정보 보유 및 이용기간 :개인정보
취급목적을 달성하여 더이상 개인정보가 불필요하게 된 경우, 지체없이
해당 정보를 파기할 것 입니다.

단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.

보존 항목 : 이름, 로그인ID, 결제기록, 주문정보, 배송정보
보존 근거 : 서비스 이용의 혼선 방지
보존 기간 : 5년 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)`}
          ></textarea>
          <p className="policy-title">
            <span>
              ※ 개인정보취급방침 관련하여 자세한 정보를 확인하시려면
              해당페이지에서 확인할 수 있습니다.
            </span>
            <span className="in">
              <span>이동</span>
              <Link to={"/policy"}></Link>
            </span>
          </p>

          <p className="policy-check-box">
            <input
              value={String(isAgree)}
              onChange={() => {
                setIsAgree(!isAgree);
              }}
              type="checkbox"
              name="policyAgree"
              id="policyAgree"
            />
            <span>
              개인정보 수집 및 이용에 대한 안내를 읽었으며 이에 동의합니다.
            </span>
          </p>
        </div>
        <div className="modal-box">
          <div className="modal-text">
            <MemberPostModal>
              <ul>
                <li>
                  <div className="cs-title">
                    <p>{t("modalType2")}</p>
                  </div>
                  <div className="cs-input">
                    <select
                      onChange={(e) => {
                        setInquireType(e.currentTarget.value);
                      }}
                      name="inquire"
                      id="select"
                    >
                      <option value="information">
                        {t("modalInformation_1")}
                      </option>
                      <option value="suggest">{t("modalSuggestion_1")}</option>
                      <option value="inquireOther">{t("modalOther")}</option>
                    </select>
                  </div>
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("newsTitle")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) => {
                        titleChange({
                          e,
                          key: "subject",
                          setSendData,
                          sendData,
                          inquireType,
                        });
                      }}
                      type="text"
                    />
                  </div>
                </li>
                <li className={"content"}>
                  <div className="cs-title">
                    <p>{t("modalContents")}</p>
                  </div>
                  <div className="cs-input">
                    <textarea
                      onChange={(e) =>
                        htmlChange({
                          e,
                          key: "contents",
                          setHtmlData,
                          htmlData,
                        })
                      }
                      rows={4}
                    />
                  </div>
                </li>
              </ul>
            </MemberPostModal>
            <div className="modal-button">
              <span
                onClick={() =>
                  isAgree === true
                    ? MailSend({ t, i18n }, sendData, htmlData)
                    : alert("약관에 동의해 주시기 바랍니다.")
                }
                className="no-select"
              >
                {t("modalSend2")}
              </span>
            </div>
          </div>
        </div>
      </MemberPostStyle>
    </>
  );
};

export default MemberPost;
