import styled from "styled-components";
import { motion } from "framer-motion";
import API from "../api/api";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Icons from "../assets/icons";
const BoardDetailStyle = styled.ul`
  .scroll {
    position: relative;
  }
`;

const BoardDetailMainStyle = styled.div`
  text-align: center;
  .board {
    display: flex;
    background: #ffffff99;
    color: #000;
    width: 100%;
    padding: 50px 0;
    .board-box {
      margin: auto;
      max-width: 1200px;
      width: 95%;
      position: relative;
      .board-head {
        width: 100%;
        max-width: 800px;
        margin: auto;
        display: flex;
        position: relative;
        background: #2d59a2;
        h1 {
          color: #fff;
          padding: 50px 0;
          font-size: 40px;
          line-height: 1em;
          margin: auto;
        }
      }
      table {
        text-align: left;
        max-width: 800px;
        margin: auto;
        width: 100%;
        border-collapse: collapse;
        .ql-video {
          width: 100%;
          aspect-ratio: 16/9;
        }
        & * {
          max-width: 100%;
          line-break: anywhere;
        }
        tr {
          border-bottom: 1px solid #ddd;
          th {
            padding: 20px;
            & + td {
              border-left: 1px solid #ddd;
            }
          }
          td {
            padding: 20px;
          }
        }
      }
    }
    .board-back {
      display: flex;
      align-items: baseline;
      position: absolute;
      line-height: 1em;
      padding: 5px 10px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 3px;
      svg path {
        transition: inherit;
        fill: #000;
      }
      &.bottom-back {
        right: 0;
      }
      &:hover {
        transition: 0.1s;
        background: #eee;
      }
      &:active {
        transition: 0.1s;
        background: #ddd;
        transform: translate(1px, 1px);
      }
    }
  }
  @media (max-width: 768px) {
    .board {
      padding: unset;
      .board-box {
        margin: 0px auto;
        .board-head {
          margin: 50px auto 0;
          h1 {
          }
        }
      }
      .board-back {
        padding: 3px 10px;
        &.bottom-back {
          bottom: 2px;
        }
      }
    }
  }
`;
const BoardDetailMain = ({ t }: any) => {
  const location = useLocation();
  const path2 = location.pathname.split("/")[2];
  const [data, setData] = useState<any>();

  useEffect(() => {
    API.BOARD_DETAIL(setData, Number(path2), "Board_Employment");
  }, [path2]);

  return (
    <BoardDetailMainStyle>
      <div className="board scroll">
        <div className="board-box">
          <motion.div
            className="board-head"
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 1 }}
          >
            {/* <div
              className="board-back no-select"
              onClick={() => window.history.back()}
            >
              <Icons.IconArrowLeft />
              <p>{t("back")}</p>
            </div> */}
            <h1>{t("recruitPosts")}</h1>
          </motion.div>
          {data && (
            <motion.table
              initial={{ translateY: "-50px", opacity: 0 }}
              animate={{ translateY: "0", opacity: 1 }}
              transition={{ delay: 0.3, ease: "easeInOut", duration: 0.7 }}
            >
              <tbody>
                <tr>
                  <th colSpan={2}>{data.title}</th>
                </tr>
                <tr>
                  <th>{t("recruitTerm")}</th>
                  <td>{data.term}</td>
                </tr>
                <tr>
                  <th>{t("recruitStatus")}</th>
                  <td>
                    {data.status_id === 1 && t("recruitStatus_1")}
                    {data.status_id === 2 && t("recruitStatus_2")}
                    {data.status_id === 3 && t("recruitStatus_3")}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.content }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </motion.table>
          )}

          <div
            className="board-back no-select bottom-back"
            onClick={() => window.history.back()}
          >
            <Icons.IconArrowLeft />
            <p>{t("back")}</p>
          </div>
        </div>
      </div>
    </BoardDetailMainStyle>
  );
};

const BoardDetail = ({ t }: any) => {
  return (
    <BoardDetailStyle className="no-select">
      <BoardDetailMain t={t} />
    </BoardDetailStyle>
  );
};

export default BoardDetail;
