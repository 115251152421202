import image1 from "./images/arista_partner_202309.jpg";


const Index = () => {
  return (
    <div>
      <table
        id="Table_01"
        width="750"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
      >
        <thead />
        <tbody>
          <tr>
            <td align="right" valign="middle" style={{ padding: "5px" }}>
              <p>
                본 메일이 보이지 않는 경우
                <a
                  href="https://www.scgs.co.kr/edm/EN2309/index.html"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5694d9" }}
                >
                  이곳
                </a>
                을 눌러 주십시오.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={image1}
                alt="img"
                width={750}
                useMap="#Map"
                border="0"
              />
            </td>
          </tr>

        </tbody>
        <tfoot />
      </table>
      <map name="Map">
        <area
          shape="rect"
          coords="1,792,752,966"
          href="https://docs.google.com/forms/d/e/1FAIpQLSctqcpRZkMPyWmrZBu6bHrceCN-HKmPcquMDoBiBY1UJqEeog/viewform"
          target="_blank"
        />

      </map>
    </div>
  );
};
export default Index;
