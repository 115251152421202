import image1 from "./images/FY24Q2-Partner-Program_01.jpg";
import image2 from "./images/FY24Q2-Partner-Program_02.jpg";
import image3 from "./images/FY24Q2-Partner-Program_03.jpg";

const Index = () => {
  return (
    <div>
      <table
        id="Table_01"
        width="750"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
      >
        <thead />
        <tbody>
          <tr>
            <td align="right" valign="middle" style={{ padding: "5px" }}>
              <p>
                본 메일이 보이지 않는 경우
                <a
                  href="https://www.scgs.co.kr/edm/24Q2SPIFF/index.html"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5694d9" }}
                >
                  이곳
                </a>
                을 눌러 주십시오.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img src={image1} alt="" width="750" useMap="#Map" border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image2} alt="" width="750" border="0" />
            </td>
          </tr>
          <tr>
            <td>
              <img src={image3} alt="" width="750" border="0" />
            </td>
          </tr>
        </tbody>
        <tfoot />
      </table>

      <map name="Map">
        <area
          shape="rect"
          coords="242,301,508,345"
          href="https://app.modusign.co.kr/link/13a8a390-f9d6-11ed-b4e5-2750841555ef/authentication?shareType=URL"
          target="_blank"
        />
      </map>
    </div>
  );
};
export default Index;
