import ReactDOMServer from "react-dom/server";
import axios from "axios";
// const API_URL = "";
const API_URL = process.env.REACT_APP_SERVER_URL;

const user_id = "6065717810188201";
const fields = "media_url,timestamp,permalink,caption";
const access_token =
  "IGQWRPSEFuM3cxU0Nhb1VqVW1fSG9RMlZAWNlpRY1NOYXNVTndzcmNYVFg5SFVDT19OMXZA6TUpaZADcycEFCb2M5V2U2WDU4TDNhSmxMNFVVT3V6b2NjVTJxR19pQWgzYWtOTHNwTjVETmxkQQZDZD";

const url = `https://graph.instagram.com/${user_id}/media`;
const params = {
  params: {
    fields: fields,
    access_token: access_token,
  },
};

const INSTAGRAM_GET_POSTS = ({ setDataList }: any) => {
  axios
    .get(url, params)
    .then((res) => {
      setDataList(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });
};

const MAIL_SEND = (sendData: any, htmlData: any, i18n: any) => {
  axios
    .post(`${API_URL}/mailer/send`, {
      receiver_id: sendData.receiver_id,
      subject: sendData.subject,
      text: sendData.text,
      html: ReactDOMServer.renderToString(sendData.html),
      // -------- DB 저장 데이터 -------
      title: sendData.subject,
      content: ReactDOMServer.renderToString(sendData.html),
      writer: htmlData.manager, // 1:1문의 - 작성자 이름
      email: htmlData.email,
      contact: htmlData.phoneNumber,
      company: htmlData.company,
      manager: htmlData.manager, // 견적 문의 - 담당자 (문의한 담당자 이름)
      legion_id: 1,
      status_id: 1, // 1:1문의 - 상태 테이블 참조용 / 진행 상태(대기중 id=1 / 처리중 id=2 / 처리완료 id=3) 나타냄
      type_id: sendData.type_id ? Number(sendData.type_id) : null, // 1:1문의 - 타입 테이블 참조용 / 글 종류(1=건의, 2=제보, 3=기타)를 나타냄
      productType_id: sendData.productType_id
        ? Number(sendData.productType_id)
        : null, // 견적 문의 - 서버 / 스토리지 / DPS / 클라이언트 솔루션 / 네트워크 / 기타 등
    })
    .then(() => {
      alert(
        i18n.language === "ko" ? "내용이 전달되었습니다." : "Transfer complete"
      );
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });
  }

const COUNT_VISITOR = () =>
  axios
    .post(`${API_URL}/countVisit`, {
      legion_id: 1,
    })
    .then(() => {})
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const MAIN_POST = (setMainData: any) =>
  axios
    .post(`${API_URL}/board/public/mainpost`, {
      legion_id: 1,
    })
    .then((res) => {
      setMainData(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const BOARD_DETAIL = (setData: any, path2: number, boardName: string) =>
  axios
    .post(`${API_URL}/board/public/post`, {
      table: boardName,
      data: {
        id: path2,
      },
    })
    .then((res) => {
      return setData(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const BOARD_LIST = ({
  setDataList,
  boardName,
  setPageNum,
  setLastPageNum,
  pageNum,
}: {
  setDataList: any;
  boardName: string;
  setPageNum: any;
  setLastPageNum: any;
  pageNum: number;
}) =>
  axios
    .post(`${API_URL}/board/public/list`, {
      table: boardName,
      data: { skip: pageNum, take: 0, legion_id: 1 },
    })
    .then((res) => {
      // Board_BuildingMng 은 리스트를 6개씩 반환해야 한다.
      // 230530 : 6개 -> 10개
      // if (boardName === "Board_BuildingMng") {
      //   // 총 리스트 갯수
      //   let count = res.data.data.counts;
      //   // 총 리스트 데이터
      //   let dataList = res.data.data.list;
      //   // 현재 페이지
      //   let page = pageNum;

      //   let pageCheck = (page: number) =>
      //     count - page * 6 > 0 ? page * 6 : count;
      //   let pageDate = dataList.slice(
      //     pageCheck(page) - page,
      //     pageCheck(page + 1) - page
      //   );
      //   setDataList(pageDate);
      //   setPageNum(page);
      //   setLastPageNum(Math.ceil(count / 6));
      // } else
      {
        let count = res.data.data.counts;
        let dataList = res.data.data.list;
        let page = pageNum;
        let pageCheck = (page: number) =>
          count - page * 10 > 0 ? page * 10 : count;
        let pageDate = dataList.slice(
          pageCheck(page) - page,
          pageCheck(page + 1) - page
        );
        setDataList(pageDate);
        setPageNum(page);
        setLastPageNum(Math.ceil(count / 10));
      }
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const NEWS_LIST = ({
  boardName,
  setNewsData,
  take,
}: {
  boardName: string;
  setNewsData?: any;
  take: number;
}) =>
  axios
    .post(`${API_URL}/board/public/list`, {
      table: boardName,
      data: { skip: 0, take: take, legion_id: 1 },
    })
    .then((res) => {
      return setNewsData(res.data.data.list.length);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const API = {
  MAIL_SEND,
  MAIN_POST,
  BOARD_LIST,
  BOARD_DETAIL,
  COUNT_VISITOR,
  INSTAGRAM_GET_POSTS,
  NEWS_LIST,
};
export default API;
