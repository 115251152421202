import styled from "styled-components";

const PagingStyle = styled.ul`
  text-align: center;
  display: flex;
  width: fit-content;
  margin: auto;
  font-size: 12px;
  li {
    width: 22px;
    aspect-ratio: 1;
    border: 1px solid #ddd;
    margin-right: 5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px;
    &:hover {
      transition: 0.1s;
      background: rgb(45, 89, 162);
      color: #fff;
    }
    &.active {
      color: #fff;
      background: rgb(45, 89, 162);
    }
    &.arrow-button {
      position: relative;
      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 2px;
        background: rgb(45, 89, 162);
        transition: 0.3s;
        transform: rotate(45deg);
        top: 6.5px;
        left: 6px;
      }
      &::before {
        top: 12.5px;
        transform: rotate(-45deg);
      }
      &.arrow-left {
        &::after {
          transform: rotate(-45deg);
        }
        &::before {
          transform: rotate(45deg);
        }
        &:hover {
          &::after,
          &::before {
            left: 4px;
            background: #fff;
          }
        }
      }
      &.arrow-right {
        &::after,
        &::before {
          left: 5.5px;
        }
        &:hover {
          &::after,
          &::before {
            left: 7px;
            background: #fff;
          }
        }
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`;
const Paging = ({
  lastPageNum,
  pageNum,
  setPageNum,
}: {
  lastPageNum?: number;
  pageNum?: number;
  setPageNum?: any;
}) => {
  const mapArray = [...Array(lastPageNum)].map((x, idx) => idx + 1);
  return (
    <PagingStyle className="no-select">
      {lastPageNum && lastPageNum > 1 ? (
        <li
          className="arrow-button arrow-left"
          onClick={() => {
            pageNum && pageNum > 0 && setPageNum(pageNum - 1);
          }}
        ></li>
      ) : (
        <></>
      )}
      {mapArray.map((res, idx) => {
        return (
          <li
            onClick={() => setPageNum(idx)}
            className={idx === pageNum ? "active" : ""}
            key={idx}
          >
            {res}
          </li>
        );
      })}
      {lastPageNum && lastPageNum > 1 ? (
        <li
          className="arrow-button arrow-right"
          onClick={() => {
            (pageNum || pageNum === 0) &&
              lastPageNum &&
              pageNum < lastPageNum - 1 &&
              setPageNum(pageNum + 1);
          }}
        ></li>
      ) : (
        <></>
      )}
    </PagingStyle>
  );
};

export default Paging;
