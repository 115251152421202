import { useState, useEffect } from "react";
import Ensecure from "./ensecure/index";
import Checkpoint from "./checkpoint/index";
import Q1spiff from "./24Q1SPIFF/index";
import Q2spiff from "./24Q2SPIFF/index";
import Q2spiff0525 from "./24Q2SPIFF/index_0525";
import Benq0720 from "./benq0720/index";
import Q3spiff from "./24Q3SPIFF/index";
import Q3spiff0821 from "./24Q3SPIFF/index_0821";
import EN2309 from "./EN2309/index";
import EN2310 from "./EN2310/index";
// import 등록할_폴더의_파일_이름 from "./폴더 이름/index";
// 위의 줄을 복사하여 페이지에서 보여줄 이벤트 이름과 경로를 지정합니다.
// 필요 없는 이벤트는 삭제하셔도 문제 없습니다. 단, 아래에서 사용된 위치의 코드도 같이 지워주셔야 합니다.
import "./edm.css";

const GetPageComponent = () => {
  useEffect(() => {
    setType(window.location.pathname.split("/")[2]);
    setType2(window.location.pathname.split("/")[3]);
  }, [window.location.pathname]);
  const [type, setType] = useState(window.location.pathname.split("/")[2]);
  const [type2, setType2] = useState(window.location.pathname.split("/")[3]);
  return (
    <div className="edm">
      {type2 === "index.html" && (
        <>
          {type === "ensecure" && <Ensecure />}
          {type === "24Q1SPIFF" && <Q1spiff />}
          {type === "24Q2SPIFF" && <Q2spiff />}
          {type === "checkpoint" && <Checkpoint />}
          {type === "benq0720" && <Benq0720 />}
          {type === "24Q3SPIFF" && <Q3spiff />}
          {type === "EN2309" && <EN2309 />}
          {type === "EN2310" && <EN2310 />}
          {/* {type === "폴더 이름" && <등록할_폴더의_파일_이름 />} */}
          {/* 위의 줄을 복사하여 맨 위에서 추가한 이벤트 경로와 이름을 등록합니다. */}
        </>
      )}

      {type === "24Q2SPIFF" &&
        type2 !== undefined &&
        type2.includes("0525") !== false && <Q2spiff0525 />}
      {type === "24Q3SPIFF" &&
        type2 !== undefined &&
        type2.includes("0821") !== false && <Q3spiff0821 />}
    </div>
  );
};
const EDMPage = () => {
  return <GetPageComponent />;
};

export default EDMPage;
