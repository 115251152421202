import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import API from "../api/api";
import { Link } from "react-router-dom";

interface SendDataType {
  e?: any;
  key?: any;
  setSendData?: any;
  sendData?: any;
  setHtmlData?: any;
  htmlData?: {};
  inquireType?: string;
  receiver_id?: number;
  html?: any;
  text?: any;
  type_id?: any;
  productType_id?: any;
  status_id?: any;
  subject?: string;
}
interface PopupInterface {
  isPopup?: Boolean;
  setIsPopup?: any;
  type?: string;
}

const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #000000;
  display: flex;
  z-index: 2;
  .header-style {
    display: flex;
    margin-bottom: 10px;
    color: #2d59a2;
    ::before {
      /* 구분자 숨김 240809 */
      /* content: ""; */
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
  }
  .post-policy {
    .policy-title {
      display: flex;
      font-size: 16px;
      margin-bottom: 10px;
      .in {
        font-size: 13px;
        border: 1px solid #2d59a2;
        transition: 0.3s;
        position: relative;
        min-width: 50px;
        display: flex;
        height: fit-content;
        margin: auto 10px;
        border-radius: 5px;
        span {
          margin: auto;
          position: relative;
          z-index: 1;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        ::after {
          transition: inherit;
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          background-color: #2d59a2;
          top: 0;
          left: 0;
          z-index: 0;
        }
        :hover {
          color: #fff;
          ::after {
            width: 100%;
          }
        }
      }
    }
  }
  textarea {
    font-family: inherit;
    padding: 10px;
    text-indent: 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    font-size: 16px;
    &:focus-visible {
      outline: none;
    }
  }
  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    background: #00000099;
    width: 100vw;
    height: 100vh;
  }
  .modal-box {
    z-index: 1;
    max-width: 1000px;
    width: 100%;
    height: 90vh;
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin: auto;
    position: relative;
    /* 닫기 버튼 */
    .close-box {
      width: 40px;
      aspect-ratio: 1;
      top: 10px;
      right: 10px;
      position: relative;
      position: absolute;
      display: flex;
      transition: 0.3s;
      :hover {
        background-color: #eee;
      }
      .close {
        position: absolute;
        top: 20px;
        left: 5px;
        width: 30px;
        height: 2px;
        background-color: #000000;
        transform: rotate(45deg);
      }
      .close1 {
        transform: rotate(-45deg);
      }
    }

    .modal-text {
      overflow: auto;
      padding: 5px;
      h1 {
        background-color: #2d59a2;
        color: #fff;
        padding: 20px 0;
      }
      .modal-button {
        display: flex;
        margin-top: 20px;
        box-sizing: border-box;
        span {
          transition: 0.3s;
          padding: 5px 30px;
          background: #2d59a2;
          color: #fff;
          :nth-of-type(1) {
            margin-left: auto;
            background-color: #eee;
            color: #000000;
            &:hover {
              background: #fff;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .header-style {
      font-size: 18px;
    }
    .post-policy {
      .policy-title {
        display: unset;
        font-size: 14px;
        margin: unset;
        .in {
          margin: 10px auto;
        }
      }
    }
    .modal-box {
      width: 95%;
      padding: 10px;
    }
    .modal-text {
      width: 100%;
      padding: unset;
      .modal-button {
        margin: 20px;
        border: none;
        box-sizing: border-box;
      }
    }
  }
`;
const CsModal = styled.div`
  h1 {
    margin-bottom: 50px;
    font-size: 30px;
  }
  .post-policy {
    margin: 20px auto 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    textarea {
      width: max-content;
      display: flex;
      font-family: inherit;
      padding: 10px;
      width: calc(100%);
      &:focus-visible {
        outline: none;
      }
    }
    .policy-check-box {
      display: flex;
      font-size: 16px;
      margin-right: auto;
      input {
        margin-right: 5px;
        width: 12px;
      }
    }
  }
  ul {
    width: 100%;
    font-size: 18px;
    li {
      display: flex;
      align-items: center;
      &.cs-contents {
        flex-direction: column;
        .cs-title {
          margin-bottom: 10px;
        }
      }
      & + li {
        margin-top: 2px;
      }
      .cs-title {
        background: #2d59a2;
        color: #fff;
        min-width: 150px;
        font-weight: bold;
        display: flex;
        height: 100%;
        min-height: 42px;
        p {
          margin: auto;
        }
      }
      .cs-input {
        padding-left: 10px;
        width: 100%;
        display: flex;
        .cs-type {
          display: flex;
          height: 100%;
          input {
            margin-right: 5px;
            & + input {
              margin-left: 25px;
            }
            width: fit-content;
          }
        }
      }
      .cs-product {
        display: flex;
        margin-left: 10px;
        li {
          input {
            margin-right: 5px;
            width: unset;
          }
          & + li {
            margin-top: unset;
            margin-left: 20px;
          }
        }
      }
    }
    .content {
      height: 114px;
    }
  }
  select,
  textarea,
  input {
    padding: 8px 5px;
    text-indent: 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
  }
  @media (max-width: 768px) {
    h1 {
      margin-bottom: 20px;
      font-size: 25px;
    }
    .post-policy {
      textarea {
        padding: 5px;
        font-size: 14px;
      }
      .policy-check-box {
        font-size: 14px;
      }
    }
    ul {
      width: unset;
      font-size: 14px;
      li {
        display: unset;
        &.cs-contents {
          flex-direction: column;
          .cs-title {
            margin-bottom: 10px;
          }
        }
        & + li {
          margin-top: 2px;
        }
        .cs-title {
          min-width: unset;
          min-height: 36px;
        }
        .cs-input {
          padding-left: unset;
          margin-bottom: 10px;
        }
        .cs-product {
          flex-wrap: wrap;
          margin-left: unset;
          margin-bottom: 10px;
          li {
            width: calc(100% / 3);
            & + li {
              margin-left: unset;
            }
          }
        }
      }
    }
  }
`;
const MailSend = (
  { t, i18n }: any,
  sendData: any,
  htmlData: any,
  setIsPopup: any
) => {
  let a = Object.entries(window.document.querySelectorAll("input"));
  let b = Object.entries(window.document.querySelectorAll("textarea"));
  try {
    [...a, ...b].forEach((e) => {
      if (e[1].value === "") {
        throw new Error("stop loop");
      }
    });
  } catch (e) {
    return alert(
      i18n.language === "ko"
        ? "모든 값을 입력해 주세요"
        : "Please enter all values"
    );
  }
  API.MAIL_SEND(sendData, htmlData, i18n);
  setIsPopup(false);
};
const htmlChange = ({ e, key, setHtmlData, htmlData }: SendDataType) => {
  // input 값 변경시 key 맞춰서 값 변경
  setHtmlData({
    ...htmlData,
    [key]: e.target.value,
  });
};
const titleChange = ({
  e,
  key,
  setSendData,
  sendData,
  inquireType,
}: SendDataType) => {
  // 제목 값 변경시 select에 맞춰서 값 변경
  switch (inquireType) {
    case "dell":
      setSendData({
        ...sendData,
        [`subject`]: `Dell : ${e.target.value}`,
      });
      break;
    case "benq":
      setSendData({
        ...sendData,
        [`subject`]: `Benq : ${e.target.value}`,
      });
      break;
    case "tplink":
      setSendData({
        ...sendData,
        [`subject`]: `TP-Link : ${e.target.value}`,
      });
      break;
    case "service":
      setSendData({
        ...sendData,
        [`subject`]: `서비스 : ${e.target.value}`,
      });
      break;
    case "other":
      setSendData({
        ...sendData,
        [`subject`]: `기타 문의 : ${e.target.value}`,
      });
      break;
    case "inquire":
      setSendData({
        ...sendData,
        [`subject`]: `제보 / 건의 : ${e.target.value}`,
      });
      break;
    case "inquireOther":
      setSendData({
        ...sendData,
        [`subject`]: `기타 제보 / 건의 : ${e.target.value}`,
      });
      break;
  }
};
const typeCheck = (type: string) => {
  switch (type) {
    case "dell":
      return "dell";
    case "benq":
      return "benq";
    case "tplink":
      return "tplink";
    case "solar":
      return "solar";
    case "service":
      return "service";
    case "other":
      return "other";
    case "inquire":
      return "inquire";
    case "inquireOther":
      return "inquireOther";

    default:
      break;
  }
};
const Modal = ({ isPopup, setIsPopup, type }: PopupInterface) => {
  const { t, i18n } = useTranslation();
  const [inquireType, setInquireType] = useState(type || "dell");
  const [htmlData, setHtmlData] = useState({
    company: "",
    manager: "",
    email: "",
    phoneNumber: "",
    contents: "",
  });
  const [html, setHtml] = useState(
    <div
      dangerouslySetInnerHTML={{
        __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
      }}
    ></div>
  );
  const [sendData, setSendData] = useState<SendDataType>({
    receiver_id: 5,
    subject: "",
    text: "",
    html: html,
    type_id: null,
    productType_id:
      type === "dell"
        ? 1
        : type === "benq"
        ? 10
        : type === "tplink"
        ? 8
        : type === "service"
        ? 17
        : null,
  });
  const [isAgree, setIsAgree] = useState(false);
  useEffect(() => {
    // 전송할 html 데이터 설정
    setHtml(
      <div
        dangerouslySetInnerHTML={{
          __html: `<div class="mail-style">
    <style>
      .mail-style * {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
      }
      .mail-style {
        display: flex;
        height: 100vh;
        background-color: #f4f4f4;
      }
      .mail-logo {
        max-width: 200px;
        width: 100%;
        display: flex;
        margin: 20px auto;
      }
      .mail-ul {
        min-height: 500px;
        max-width: 500px;
        width: 95%;
        margin: auto;
      }
      .mail-li {
        display: flex;
        margin-bottom: 15px;
      }
      p {
        padding: 5px 10px;
      }
      .subject {
        min-width: 100px;
        font-weight: bold;
      }
      .divider {
        min-width: 10px;
      }
      .value {
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 #00000011;
      }
    </style>
    <ul class="mail-ul">
      <div class="mail-logo">
        <svg
          id="_\uB808\uC774\uC5B4_2"
          data-name="\uB808\uC774\uC5B4 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 150.73 24.57"
        >
          <defs>
            <style>
              .logo-1 {
                fill: #1658a7;
              }
  
              .logo-2 {
                fill: #97ca87;
              }
  
              .logo-3 {
                fill: #002856;
              }
  
              .logo-4 {
                fill: #00afe1;
              }
  
              .logo-5 {
                fill: #1958a6;
              }
  
              .logo-6 {
                fill: #a4a1c8;
              }
            </style>
          </defs>
          <g id="Layer_1" data-name="Layer 1">
            <g>
              <g>
                <g>
                  <path
                    class="logo-5"
                    d="m91.27,11.81v-.55h-4.98l-.02-1.07h0c0-.31-.45-.56-1-.56h-1.03l.04,1.63h-5v.55c0,.31.45.56,1,.56h9.98c.55,0,1-.25,1-.56Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m81.54,10.14c.62-.07,1.27-.23,1.94-.48.92-.35,1.53-.81,1.8-1.39.29.56.89,1.02,1.82,1.38.66.25,1.29.42,1.88.49.4.05.82-.04,1.16-.34.34-.31.52-.47.52-.47-1.02,0-2-.29-2.95-.86-.95-.57-1.42-1.24-1.42-2.09,0-.31-.45-.56-1-.56h-1.02v.53c0,.85-.47,1.56-1.42,2.12-.95.56-1.93.85-2.96.86,0,0,.18.17.52.47.34.31.8.39,1.12.35Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m89.81,17.54v-.55h-6.52v-.89h5.28c.55,0,1-.25,1-.56v-1.91c0-.31-.45-.56-1-.56h-7.43v.56c0,.31.45.56,1,.56h5.42v.85h-6.28v1.74c0,.4.13.72.39.96.26.25.59.37,1.01.37h6.12c.55,0,1-.25,1-.56Z"
                  ></path>
                </g>
                <g>
                  <path
                    class="logo-5"
                    d="m121.09,11.02c-.98.8-1.7,1.21-2.66,1.24,0,0,.14.14.46.45.32.31.79.42,1.12.36.55-.1,1.1-.3,1.77-.68.95-.54,1.55-1.07,1.79-1.74.25.66.85,1.17,1.8,1.74.67.4,1.22.59,1.78.68.38.07.71.02,1.06-.33.35-.34.5-.49.5-.49-.96-.03-1.68-.44-2.66-1.24-.98-.79-1.47-1.8-1.47-3v-.39h2.59c.55,0,1-.25,1-.56v-.58h-9.2v.58c0,.31.45.56,1,.56h2.59v.39c0,1.21-.49,2.21-1.47,3Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m129.37,15.96v-.57h-11.58v.57c0,.31.45.56,1,.56h9.58c.55,0,1-.25,1-.56Z"
                  ></path>
                </g>
                <g>
                  <path
                    class="logo-5"
                    d="m102.97,13.96c.55,0,1-.25,1-.56v-.57h-11.85v.57c0,.31.45.56,1,.56h3.92v4.14h1c.55,0,1-.25,1-.56v-3.58h3.92Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m94.71,7.09h5.57v1.26h-6.47v2.13c0,.4.11.7.34.92.23.22.55.33.98.33h6.39c.55,0,1-.25,1-.56v-.56h-6.71v-1.11h5.47c.55,0,1-.26,1-.57v-2.42c0-.31-.45-.56-1-.56h-7.58v.58c0,.31.45.56,1,.56Z"
                  ></path>
                </g>
                <g>
                  <path
                    class="logo-5"
                    d="m111.82,9.58v.55c0,.31.45.56,1,.56h1.21v4.2h1c.55,0,1-.25,1-.56v-7.95c0-.31-.45-.56-1-.56h-1v1.4h-2.22v.56c0,.31.45.57,1,.57h1.21v1.24h-2.22Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m116.04,17.54v-.58h-6.61v-2.26c0-.31-.45-.56-1-.56h-1v2.63c0,.41.14.74.4.98.27.24.6.36.99.36h6.21c.55,0,1-.25,1-.56Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m108.71,10.69c.18.53.59,1.02,1.23,1.48.4.29.8.5,1.2.63.3.1.81.06,1.15-.23.34-.29.53-.46.53-.46-.8-.16-1.52-.6-2.14-1.32-.63-.72-.94-1.65-.94-2.79v-1.42c0-.31-.45-.56-1-.56h-1.03v1.99c0,1.12-.31,2.04-.94,2.78-.62.73-1.35,1.18-2.17,1.34,0,0,.19.16.53.45.34.29.81.36,1.17.23.39-.14.78-.34,1.19-.62.65-.46,1.06-.95,1.22-1.47Z"
                  ></path>
                </g>
                <g>
                  <g>
                    <path
                      class="logo-5"
                      d="m145.69,13.96c.55,0,1-.25,1-.56v-.57h-11.49v.57c0,.31.45.56,1,.56h3.75v4.12h1c.55,0,1-.25,1-.56v-3.56h3.73Z"
                    ></path>
                    <path
                      class="logo-5"
                      d="m138.53,9.53c-.95.68-1.93,1.02-2.96,1.03,0,0,.15.13.52.47s.86.38,1.08.36c.65-.08,1.32-.27,2-.58.92-.42,1.51-.92,1.79-1.51.29.58.89,1.09,1.8,1.51.64.29,1.26.47,1.86.57.4.07.9-.08,1.24-.36.33-.28.49-.45.49-.45-1.02,0-2-.35-2.95-1.03-.95-.68-1.43-1.47-1.43-2.37v-.07h2.53c.55,0,1-.25,1-.56v-.58h-9.08v.58c0,.31.45.56,1,.56h2.53v.07c0,.9-.47,1.69-1.42,2.37Z"
                    ></path>
                  </g>
                  <g>
                    <path
                      class="logo-5"
                      d="m133.96,18.87c-.51,0-.86-.34-1.03-.63-.43-.76-.81-1.57-1.13-2.43-.41-1.1-.62-2.36-.62-3.78,0-1.2.19-2.38.56-3.54.32-.98.74-1.91,1.26-2.8.18-.32.5-.5,1-.5h.69c-.59,1.06-1.05,2.16-1.36,3.29-.31,1.13-.47,2.32-.47,3.55s.15,2.42.45,3.56c.29,1.13.76,2.23,1.38,3.29h-.73Z"
                    ></path>
                    <path
                      class="logo-5"
                      d="m147.95,5.19c.49,0,.75.18.91.44.49.82.9,1.7,1.25,2.64.42,1.1.62,2.36.62,3.78,0,1.2-.19,2.38-.56,3.54-.32.97-.73,1.9-1.26,2.78-.21.32-.51.51-1.02.51h-.68c.59-1.06,1.05-2.15,1.36-3.28.31-1.12.47-2.31.47-3.54s-.15-2.42-.45-3.56c-.3-1.14-.76-2.24-1.38-3.29h.74Z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    class="logo-5"
                    d="m39.5,17.54V6.37c0-.31-.45-.56-1-.56h-.88v12.29h.88c.55,0,1-.25,1-.56Z"
                  ></path>
                  <path
                    class="logo-5"
                    d="m36.05,6.06h-.88v3.26h-.74c-.12-.64-.42-1.21-.9-1.71-.64-.66-1.41-.99-2.31-.99s-1.67.33-2.32.99c-.65.66-.97,1.45-.97,2.37s.32,1.72.97,2.38c.65.65,1.42.98,2.32.98s1.67-.33,2.31-.98c.53-.54.83-1.16.93-1.89h.71s0,7.38,0,7.38h.88c.55,0,1-.25,1-.56V6.62c0-.31-.45-.56-1-.56Zm-3.83,5.57c-.26.44-.59.66-1,.66s-.74-.22-1.01-.66c-.27-.44-.4-.99-.4-1.64s.13-1.19.4-1.63c.27-.44.6-.66,1.01-.66s.74.22,1,.66c.26.44.39.98.39,1.63s-.13,1.2-.39,1.64Z"
                  ></path>
                </g>
                <path
                  class="logo-5"
                  d="m51.79,16.77h-9.58c-.55,0-1-.25-1-.57v-.58h11.58v.58c0,.31-.45.57-1,.57Zm-8.52-3.73c.62-.12,1.26-.32,1.93-.66.95-.48,1.55-1.06,1.79-1.74.25.66.85,1.24,1.8,1.73.66.34,1.29.56,1.89.66.48.07.95-.07,1.24-.36.29-.29.45-.43.45-.43-.96-.04-1.93-.45-2.91-1.25-.98-.79-1.47-1.79-1.47-3v-1.08c0-.31-.45-.56-1-.56h-1.02v1.65c0,1.21-.49,2.21-1.47,3-.98.79-1.95,1.21-2.91,1.25,0,0,.16.15.47.46.31.3.7.43,1.2.34Z"
                ></path>
                <path
                  class="logo-5"
                  d="m64.51,6.62v11.16c0,.31-.45.56-1,.56h-.96V6.06h.96c.55,0,1,.25,1,.56Zm-3.29,4.99c-.27-.79-.41-1.7-.41-2.72v-1.94c0-.31-.45-.56-1-.56h-.93v2.51c0,.9-.06,1.67-.19,2.3-.12.63-.34,1.07-.66,1.32-.3-.23-.51-.66-.65-1.3-.14-.64-.21-1.41-.21-2.32v-1.94c0-.31-.45-.56-1-.56h-.93v2.51c0,1.02-.13,1.93-.4,2.72-.27.79-.68,1.33-1.23,1.6,0,0,.18.16.51.45.33.29.84.28,1.08.06.15-.13.31-.3.45-.5.34-.46.52-.83.56-1.12.04.32.25.72.65,1.22.39.49.77.77,1.15.82.39-.05.78-.32,1.17-.81.39-.49.62-.9.67-1.22.02.28.2.65.53,1.11.15.21.3.38.46.52.28.23.76.22,1.1-.08.34-.3.5-.44.5-.44-.56-.28-.97-.81-1.24-1.6Z"
                ></path>
                <path
                  class="logo-5"
                  d="m74.57,13.27s-.19.17-.51.45c-.32.27-.82.39-1.24.24-.39-.14-.78-.36-1.16-.66-.64-.51-1.04-1.04-1.2-1.59-.15.56-.55,1.08-1.19,1.58-.4.31-.81.54-1.21.68-.42.11-.91,0-1.23-.27-.32-.28-.49-.43-.49-.43.82-.16,1.54-.65,2.16-1.48.62-.83.94-1.83.94-3v-1.2h-1.32c-.55,0-1-.25-1-.56v-.56h6.68v.56c0,.31-.45.56-1,.56h-1.33v1.2c0,1.17.31,2.17.94,3,.63.83,1.35,1.33,2.16,1.48Zm2.63,4.27V6.37c0-.31-.45-.56-1-.56h-1v12.29h1c.55,0,1-.25,1-.56Z"
                ></path>
              </g>
              <g>
                <path
                  class="logo-3"
                  d="m0,6.4C.02,3.03,3.03.02,6.4,0h11.77c3.37.02,6.37,3.03,6.4,6.4h0v11.77c-.03,3.36-3.03,6.37-6.4,6.4H6.4c-3.37-.03-6.38-3.04-6.4-6.4V6.4Zm1,11.77c0,1.37.64,2.73,1.66,3.74,1.01,1.01,2.37,1.66,3.75,1.66h11.77c1.38,0,2.74-.64,3.75-1.66,1.01-1.01,1.66-2.37,1.66-3.74h0V6.4c0-1.38-.64-2.73-1.66-3.74-1.01-1.01-2.37-1.66-3.75-1.66H6.4c-1.38,0-2.74.65-3.75,1.66-1.01,1.01-1.66,2.37-1.66,3.74h0v11.77Z"
                ></path>
                <path
                  class="logo-1"
                  d="m3.88,11.96v-4.01c0-2.24,1.82-4.05,4.06-4.05h8.64c2.24,0,4.06,1.82,4.06,4.05v8.65c0,2.24-1.82,4.05-4.06,4.05H7.94c-2.24,0-4.06-1.82-4.06-4.05v-4.64Z"
                ></path>
                <path
                  class="logo-2"
                  d="m9.58,8.02c-2.26.17-4.21.51-5.62.82v.53c3.32-.73,9.93-1.7,16.64.06v-.53c-4.09-1.05-8.05-1.11-11.02-.88Z"
                ></path>
                <path
                  class="logo-4"
                  d="m3.96,10.01v.73c2.63-.39,9.93-1.11,16.64,1.57v-.77c-6.72-2.58-13.89-1.93-16.64-1.53Z"
                ></path>
                <path
                  class="logo-4"
                  d="m3.96,15.82v.33c0,.25.03.51.07.73,3.91-1.42,9.08-2.89,16.57-2.53v-.93c-6.55-.24-12.06.77-16.64,2.4Z"
                ></path>
                <path
                  class="logo-6"
                  d="m3.96,13.28v.62c4.25-.33,11.04-.06,16.57,2.99.04-.22.06-.44.07-.66-5.58-2.98-12.36-3.27-16.64-2.94Z"
                ></path>
                <path
                  class="logo-4"
                  d="m3.96,13.28v.39c4.18-1.01,10.23-1.89,16.64-.88v-.38c-6.39-1-12.44-.14-16.64.87Z"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <li class="mail-li">
        <p class="subject">업체명</p>
        <p class="divider">:</p>
        <p class="value">${htmlData.company}</p>
      </li>
      <li class="mail-li">
        <p class="subject">담당자</p>
        <p class="divider">:</p>
        <p class="value">${htmlData.manager}</p>
      </li>
      <li class="mail-li">
        <p class="subject">e-mail</p>
        <p class="divider">:</p>
        <p class="value">${htmlData.email}</p>
      </li>
      <li class="mail-li">
        <p class="subject">연락처</p>
        <p class="divider">:</p>
        <p class="value">${htmlData.phoneNumber}</p>
      </li>
      <li class="mail-li">
        <p class="subject">내용</p>
        <p class="divider">:</p>
        <p class="value">${htmlData.contents}</p>
      </li>
    </ul>`,
        }}
      ></div>
    );
    setSendData({
      ...sendData,
      html: html,
    });
  }, [htmlData]);

  useEffect(() => {
    switch (inquireType) {
      case "dell":
        setSendData({
          ...sendData,
          receiver_id: 1,
          subject: `Dell : ${String(sendData.subject).replace(/^.+?: /, "")}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "benq":
        setSendData({
          ...sendData,
          receiver_id: 5,
          subject: `Benq : ${String(sendData.subject).replace(/^.+?: /, "")}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "tplink":
        setSendData({
          ...sendData,
          receiver_id: 5,
          subject: `TP-Link : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "service":
        setSendData({
          ...sendData,
          receiver_id: 8,
          subject: `서비스 : ${String(sendData.subject).replace(/^.+?: /, "")}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "other":
        setSendData({
          ...sendData,
          receiver_id: 5,
          subject: `기타 견적 문의 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "inquire":
        setSendData({
          ...sendData,
          receiver_id: 5,
          subject: `제보 / 건의 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      case "inquireOther":
        setSendData({
          ...sendData,
          receiver_id: 5,
          subject: `기타 문의 : ${String(sendData.subject).replace(
            /^.+?: /,
            ""
          )}`,
          html: (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mail-style">
        <style>
          .mail-style * {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 16px;
          }
          .mail-style {
            display: flex;
            height: 100vh;
            background-color: #f4f4f4;
          }
          .mail-logo {
            width: fit-content;
            display: flex;
            margin: 20px auto;
            font-weight: bold;
            font-size: 26px;
            color: #2d59a2;
          }
          .mail-ul {
            min-height: 500px;
            max-width: 500px;
            width: 95%;
            margin: auto;
          }
          .mail-li {
            display: flex;
            margin-bottom: 15px;
          }
          .subject {
            min-width: 100px;
            font-weight: bold;
            padding: 5px 10px;
          }
          .divider {
            min-width: 10px;
            padding: 5px 10px;
          }
          .value {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #00000011;
            padding: 5px 10px;
          }
        </style>
        <ul class="mail-ul">
          <div class="mail-logo">에스씨지솔루션즈(주)</div>
          <li class="mail-li">
            <span class="subject">업체명</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.company}</span>
          </li>
          <li class="mail-li">
            <span class="subject">담당자</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.manager}</span>
          </li>
          <li class="mail-li">
            <span class="subject">e-mail</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.email}</span>
          </li>
          <li class="mail-li">
            <span class="subject">연락처</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.phoneNumber}</span>
          </li>
          <li class="mail-li">
            <span class="subject">내용</span>
            <span class="divider">:</span>
            <span class="value">${htmlData.contents}</span>
          </li>
        </ul>
      </div>
      `,
              }}
            ></div>
          ),
        });
        break;
      default:
        break;
    }
    inquireType === "other" &&
      setSendData({
        ...sendData,
        ["productType_id"]: 6,
        ["type_id"]: null,
      });
    (inquireType === "inquireOther" || inquireType === "inquire") &&
      setSendData({
        ...sendData,
        ["type_id"]: 3,
        ["productType_id"]: null,
      });
  }, [inquireType]);

  return (
    <>
      <ModalStyle>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="modal-background"
          onClick={() => isPopup && setIsPopup(!isPopup)}
        />
        <motion.div
          initial={{ translateY: "-30px", opacity: 0 }}
          animate={{ translateY: "0", opacity: 1 }}
          exit={{ translateY: "-30px", opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="modal-box"
        >
          <div
            className="close-box pointer"
            onClick={() => isPopup && setIsPopup(!isPopup)}
          >
            <div className="close close1"></div>
            <div className="close close2"></div>
          </div>
          <div className="modal-text">
            <CsModal>
              <h1>{t("csButton_1")}</h1>
              <div className="post-policy">
                <h2 className="header-style">
                  개인정보 수집 및 이용에 대한 안내
                </h2>
                <textarea
                  disabled
                  rows={5}
                  defaultValue={`개인정보를 제공받는 자 : 에스씨지솔루션즈(주)
개인정보를 제공받는 자의 개인정보 이용 목적 : 소리함(건의함/제보함/기타 문의)
제공하는 개인정보 항목 : 이름, 연락처, 쿠키 등
개인정보를 제공받는 자의 개인정보 보유 및 이용기간 :개인정보
취급목적을 달성하여 더이상 개인정보가 불필요하게 된 경우, 지체없이
해당 정보를 파기할 것 입니다.

단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.

보존 항목 : 이름, 로그인ID, 결제기록, 주문정보, 배송정보
보존 근거 : 서비스 이용의 혼선 방지
보존 기간 : 5년 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)`}
                ></textarea>
                <p className="policy-title">
                  <span>
                    ※ 개인정보취급방침 관련하여 자세한 정보를 확인하시려면
                    해당페이지에서 확인할 수 있습니다.
                  </span>
                  <span className="in">
                    <span>이동</span>
                    <Link to={"/policy"}></Link>
                  </span>
                </p>
                <p className="policy-check-box">
                  <input
                    value={String(isAgree)}
                    onChange={() => {
                      setIsAgree(!isAgree);
                    }}
                    type="checkbox"
                    name="policyAgree"
                    id="policyAgree"
                  />
                  <label htmlFor="policyAgree">
                    개인정보 수집 및 이용에 대한 안내를 읽었으며 이에
                    동의합니다.
                  </label>
                </p>
              </div>
              <ul>
                <li>
                  <div className="cs-title">
                    <p>{t("modalType2")}</p>
                  </div>
                  <div className="cs-input">
                    <select
                      onChange={(e) => {
                        setInquireType(e.currentTarget.value);
                      }}
                      name="inquire"
                      id="select"
                      defaultValue={typeCheck(type || "dell")}
                    >
                      <option value="dell">DELL Technologies</option>
                      <option value="benq">BenQ</option>
                      <option value="tplink">tp-link</option>
                      <option value="service">Service</option>
                    </select>
                  </div>
                </li>
                <li>
                  {inquireType !== "inquire" &&
                  inquireType !== "inquireOther" ? (
                    <div className="cs-title">
                      <p>{t("modalProductType")}</p>
                    </div>
                  ) : (
                    <div className="cs-title">
                      <p>{t("modalInquiryType")}</p>
                    </div>
                  )}
                  {inquireType === "dell" && (
                    <ul
                      className="cs-product"
                      onChange={(e) =>
                        setSendData({
                          ...sendData,
                          ["productType_id"]: (e.target as HTMLInputElement)
                            .value,
                          ["type_id"]: null,
                        })
                      }
                    >
                      <li>
                        <input
                          name="inquire_type"
                          value={1}
                          type="radio"
                          defaultChecked
                        />
                        {t("modalDell_1")}
                      </li>
                      <li>
                        <input name="inquire_type" value={2} type="radio" />
                        {t("modalDell_2")}
                      </li>
                      <li>
                        <input name="inquire_type" value={3} type="radio" />
                        DPS
                      </li>
                      <li>
                        <input name="inquire_type" value={4} type="radio" />
                        {t("modalDell_3")}
                      </li>
                      <li>
                        <input name="inquire_type" value={5} type="radio" />
                        {t("modalDell_4")}
                      </li>
                      <li>
                        <input name="inquire_type" value={6} type="radio" />
                        {t("buildingOtherDetails")}
                      </li>
                    </ul>
                  )}
                  {inquireType === "benq" && (
                    <ul
                      className="cs-product"
                      onChange={(e) =>
                        setSendData({
                          ...sendData,
                          ["productType_id"]: (e.target as HTMLInputElement)
                            .value,
                          ["type_id"]: null,
                        })
                      }
                    >
                      <li>
                        <input
                          name="inquire_type"
                          value={10}
                          type="radio"
                          defaultChecked
                        />
                        {t("modalBenq_1")}
                      </li>
                      <li>
                        <input name="inquire_type" value={6} type="radio" />
                        {t("buildingOtherDetails")}
                      </li>
                    </ul>
                  )}
                  {inquireType === "tplink" && (
                    <ul
                      className="cs-product"
                      onChange={(e) =>
                        setSendData({
                          ...sendData,
                          ["productType_id"]: (e.target as HTMLInputElement)
                            .value,
                          ["type_id"]: null,
                        })
                      }
                    >
                      <li>
                        <input
                          name="inquire_type"
                          value={8}
                          type="radio"
                          defaultChecked
                        />
                        {t("Omada")}
                      </li>
                      <li>
                        <input name="inquire_type" value={9} type="radio" />
                        {t("VIGI")}
                      </li>
                      <li>
                        <input name="inquire_type" value={11} type="radio" />
                        {t("TAPO")}
                      </li>
                    </ul>
                  )}
                  {inquireType === "service" && (
                    <ul
                      className="cs-product"
                      onChange={(e) =>
                        setSendData({
                          ...sendData,
                          ["productType_id"]: (e.target as HTMLInputElement)
                            .value,
                          ["type_id"]: null,
                        })
                      }
                    >
                      <li>
                        <input
                          name="inquire_type"
                          value={17}
                          type="radio"
                          defaultChecked
                        />
                        {t("modalService_1")}
                      </li>
                      <li>
                        <input name="inquire_type" value={6} type="radio" />
                        {t("buildingOtherDetails")}
                      </li>
                    </ul>
                  )}
                  {inquireType === "inquire" && (
                    <ul
                      className="cs-product"
                      onChange={(e) =>
                        setSendData({
                          ...sendData,
                          ["type_id"]: (e.target as HTMLInputElement).value,
                          ["productType_id"]: null,
                        })
                      }
                    >
                      <li>
                        <input
                          name="inquire_type"
                          value={1}
                          type="radio"
                          defaultChecked
                        />
                        {t("modalInformation_1")}
                      </li>
                      <li>
                        <input name="inquire_type" value={2} type="radio" />
                        {t("modalSuggestion_1")}
                      </li>
                    </ul>
                  )}
                  {inquireType === "other" && (
                    <ul className="cs-product">
                      <li>{t("buildingOtherDetails")}</li>
                    </ul>
                  )}

                  {inquireType === "inquireOther" && (
                    <ul className="cs-product">
                      <li>{t("buildingOtherDetails")}</li>
                    </ul>
                  )}
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("newsTitle")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) =>
                        titleChange({
                          e,
                          key: "subject",
                          setSendData,
                          sendData,
                          inquireType,
                        })
                      }
                      type="text"
                    />
                  </div>
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("modalCompany")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) =>
                        htmlChange({
                          e,
                          key: "company",
                          setHtmlData,
                          htmlData,
                        })
                      }
                      type="text"
                    />
                  </div>
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("modalManager")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) =>
                        htmlChange({
                          e,
                          key: "manager",
                          setHtmlData,
                          htmlData,
                        })
                      }
                      type="text"
                    />
                  </div>
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("modalEmail")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) =>
                        htmlChange({ e, key: "email", setHtmlData, htmlData })
                      }
                      type="email"
                    />
                  </div>
                </li>
                <li>
                  <div className="cs-title">
                    <p>{t("modalContact")}</p>
                  </div>
                  <div className="cs-input">
                    <input
                      onChange={(e) =>
                        htmlChange({
                          e,
                          key: "phoneNumber",
                          setHtmlData,
                          htmlData,
                        })
                      }
                      type="tel"
                    />
                  </div>
                </li>
                <li className={"content"}>
                  <div className="cs-title">
                    <p>{t("modalContents")}</p>
                  </div>
                  <div className="cs-input">
                    <textarea
                      onChange={(e) =>
                        htmlChange({
                          e,
                          key: "contents",
                          setHtmlData,
                          htmlData,
                        })
                      }
                      rows={4}
                    />
                  </div>
                </li>
              </ul>
            </CsModal>
            <div className="modal-button pointer">
              <span
                className="no-select"
                onClick={() => {
                  setIsPopup(false);
                }}
              >
                {t("modalCancle")}
              </span>
              <span
                onClick={() =>
                  isAgree === true
                    ? MailSend({ t, i18n }, sendData, htmlData, setIsPopup)
                    : alert("약관에 동의해 주시기 바랍니다.")
                }
                className="no-select"
              >
                {t("modalSend")}
              </span>
            </div>
          </div>
        </motion.div>
      </ModalStyle>
    </>
  );
};

export default Modal;
