import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const SiteMapStyle = styled.ul`
  .scroll {
    position: relative;
  }
`;
const SitemapMainStyle = styled.div`
  .banner-image {
    display: flex;
    .banner-text {
      margin: auto;
      background: #2d59a2;
      color: #fff;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 40px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const SiteMapMain = ({ t }: any) => {
  return (
    <SitemapMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("footerSiteMap")}
          </motion.h1>
        </motion.div>
      </div>
    </SitemapMainStyle>
  );
};
const SiteMapContentsStyle = styled.div`
  margin: 50px auto;
  section {
    margin: 0 auto;
    max-width: 750px;
    width: 95%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    article {
      width: 250px;
      padding: 10px;
      h1 {
        color: #2d59a2;
        padding: 10px;
        font-size: 24px;
        border-bottom: 2px solid #2d59a2;
      }
      .depth1-ul {
        > li {
          background: #f4f4f4;
          margin-bottom: 5px;
          padding: 10px;
        }
      }
      .depth2-ol {
        > li {
          padding: 3px 0 3px 20px;
          ::before {
            content: "- ";
          }
        }
      }
      li {
        position: relative;
        transition: 0.3s;
        :hover {
          background-color: #ddd;
        }
        a {
          position: absolute;
          top: 0%;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    section {
      article {
        padding: 5px;
        h1 {
          font-size: 20px;
        }
      }
    }
  }
`;
const SiteMapContents = () => {
  return (
    <SiteMapContentsStyle>
      <section>
        <article>
          <h1>회사소개</h1>
          <ul className="depth1-ul">
            <li>
              일반 현황<Link to={"/introduce"} state={0}></Link>
            </li>
            <li>
              연혁<Link to={"/introduce"} state={1}></Link>
            </li>
            <li>
              오시는 길<Link to={"/introduce"} state={2}></Link>
            </li>
          </ul>
        </article>
        <article>
          <h1>관계사 소개</h1>
          <ul className="depth1-ul">
            <li>
              국내 관계사
              <Link
                to={"/partner"}
                state={{
                  type: true,
                }}
              ></Link>
            </li>
            <li>
              해외 관계사
              <Link
                to={"/partner"}
                state={{
                  type: false,
                }}
              ></Link>
            </li>
          </ul>
        </article>
        <article>
          <h1>사업 영역</h1>
          <ul className="depth1-ul">
            <li>
              IT 사업<Link to={"/business/it"}></Link>
            </li>
            <li>
              신재생에너지사업<Link to={"/business/solar"}></Link>
            </li>
            <li>
              종합관리사업<Link to={"/business/managing"}></Link>
            </li>
            <li>
              도시가스 고객지원사업<Link to={"/business/support"}></Link>
            </li>
          </ul>
        </article>
        <article>
          <h1>PR/IR</h1>
          <ul className="depth1-ul">
            <li>
              NEWS
              <Link
                to={"/news"}
                state={{
                  type: 1,
                }}
              ></Link>
            </li>
            <li>
              SNS
              <Link
                to={"/news"}
                state={{
                  type: 2,
                }}
              ></Link>
            </li>
            <li>
              전자공고
              <Link
                to={"/news"}
                state={{
                  type: 3,
                }}
              ></Link>
            </li>
          </ul>
        </article>
        <article>
          <h1>인재 채용</h1>
          <ul className="depth1-ul">
            <li>
              채용정보
              <Link
                to={"/recruit"}
                state={{
                  type: 0,
                }}
              ></Link>
            </li>
            <li>
              채용공고
              <Link
                to={"/recruit"}
                state={{
                  type: 1,
                }}
              ></Link>
            </li>
          </ul>
        </article>
        <article>
          <h1>고객지원</h1>
          <ul className="depth1-ul">
            <li>
              개인정보처리방침<Link to={"/policy"}></Link>
            </li>
            <li>
              담당자연락처<Link to={"/cs"}></Link>
            </li>
            <li>
              <Link to={"/post"}></Link>소리함
            </li>
          </ul>
        </article>
      </section>
    </SiteMapContentsStyle>
  );
};

const SiteMap = ({ t }: any) => {
  return (
    <SiteMapStyle className="no-select">
      <SiteMapMain t={t} />
      <SiteMapContents />
    </SiteMapStyle>
  );
};

export default SiteMap;
