const BannerResize = (isOpenedBanner: boolean, path: string | boolean) => {
  var resizeTarget: any[] = [];
  const bodyClass = document.getElementsByClassName("body");
  const isMain = (path: string | boolean) => {
    if (path === "" || path === undefined) {
      return true;
    } else if (path.toString().length > 0) {
      return false;
    }
  };

  // 메인화면일 경우
  if (isMain(path)) {
    const list = Object.entries(document.getElementsByClassName("scroll"));
    list.forEach((listItem) => {
      resizeTarget.push(listItem[1]);
    });
    // list(.scroll) 전체 실행
    resizeTarget.forEach((el) => {
      el.style.minHeight = `calc(100vh - ${isOpenedBanner ? "100px" : "50px"})`;
      el.style.height = `calc(100vh - ${isOpenedBanner ? "100px" : "50px"})`;
    });
  }

  // .body 태그는 작동시 항상 설정
  (bodyClass[0] as HTMLElement).style.minHeight = `calc(100vh - ${
    isOpenedBanner ? "100px" : "50px"
  })`;
  (bodyClass[0] as HTMLElement).style.height = `calc(100vh - ${
    isOpenedBanner ? "100px" : "50px"
  })`;
};
export default BannerResize;
