import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Icons from "../assets/icons";
import Modal from "./Modal";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}
const data = [
  {
    head: {
      title: "ITO",
      color: {
        main: "#2F5597",
        hover: "#446db4",
        active: "#1a4185",
        background: "#f4f4f4",
      },
      type: "other",
      description: ["itoDescription"],
    },
    item: [
      {
        title: "ito_1_title",
        pageLink: "https://www.dell.com/ko-kr/dt/servers/index.htm",
        subList: [
          "ito_1_sub_1_list_1",
          "ito_1_sub_1_list_2",
          "ito_1_sub_1_list_3",
          "ito_1_sub_1_list_4",
        ],
        subList2_title: "ito_1_sub_2_title_1",
        subList2: [
          "ito_1_sub_2_list_1",
          "ito_1_sub_2_list_2",
          "ito_1_sub_2_list_3",
          "ito_1_sub_2_list_4",
          "ito_1_sub_2_list_5",
          "ito_1_sub_2_list_6",
        ],
      },
      {
        title: "ito_2_title",
        pageLink: "https://www.dell.com/ko-kr/dt/servers/index.htm",
        itemList: [
          {
            name: "ito_2_con_1",
            itemImg: (
              <Icons.BrandSeoulgas3
                style={{ height: "fit-content", margin: "auto 5px" }}
              />
            ),
          },
          {
            name: "ito_2_con_2",
            itemImg: (
              <Icons.BrandScgLab2
                style={{ height: "fit-content", margin: "auto 42px" }}
              />
            ),
          },
          {
            name: "ito_2_con_3",
            itemImg: (
              <Icons.BrandSeoulCng2
                style={{ height: "fit-content", margin: "auto 23px" }}
              />
            ),
          },
          {
            name: "ito_2_con_4",
            itemImg: (
              <Icons.BrandJbcorp2
                style={{ height: "fit-content", margin: "auto 27px" }}
              />
            ),
          },
        ],
      },
    ],
  },
];

const data2 = [
  <Icons.IconIto01 />,
  <Icons.IconIto02 />,
  <Icons.IconIto03 />,
  <Icons.IconIto04 />,
];
const BusinessItItoStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessItItoMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-ito.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessItItoMain = () => {
  return (
    <BusinessItItoMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            ITO
          </motion.h1>
        </motion.div>
      </div>
    </BusinessItItoMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .main-contents {
    text-align: center;
    .main-contents-header {
      padding: 50px 0;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 30px;
      }
      button {
        display: flex;
        width: 150px;
        height: 40px;
        margin: 20px auto 0;
        border: 1px solid ${(props) => props.main};
        transition: 0.3s;
        position: relative;
        ::after {
          transition: inherit;
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          background-color: ${(props) => props.main};
          top: 0;
          left: 0;
          z-index: 0;
        }
        :hover {
          ::after {
            width: 100%;
          }
          p {
            color: #fff;
          }
        }
        p {
          transition: inherit;
          margin: auto;
          font-size: 14px;
          z-index: 1;
        }
      }
    }
    .main-contents-body {
      margin: 50px 0;
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
        aside {
          margin: auto 5px auto auto;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: ${(props) => props.main};
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      ul {
        .main-contents-item {
          transition: 0.3s;
        }
      }
      &.ito-business {
        .ito-business-ul-box {
          display: flex;
          max-width: 1000px;
          margin: auto;
          padding: 50px 0;
          ul {
            width: 50%;
          }
          .ito-business-ul-1 {
            display: flex;
            flex-wrap: wrap;
            li {
              width: calc(50% - 2px);
              aspect-ratio: 2/1;
              margin: 0 auto 2px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: ${(props) => props.main};
              :hover {
                background: ${(props) => props.hover};
              }
              .main-contents-item-icon {
                width: 50px;
                display: flex;
                margin: 0 auto 10px;
                svg {
                  width: 100%;
                  height: auto;
                  path,
                  rect {
                    fill: #fff;
                  }
                }
              }
              p {
                font-weight: bold;
                font-size: 26px;
                line-height: 1.1em;
                padding: 0 5px;
                color: #fff;
                margin: 0 auto;
              }
            }
          }
          .ito-business-ul-2 {
            margin-left: 30px;
            font-size: 23px;
            margin-bottom: 4px;
            h2 {
              padding: 5px;
              background-color: #f4f4f4;
              color: ${(props) => props.main};
            }
            li {
              padding: 3px 0;
              display: flex;
              text-align: left;
              line-height: 1.2em;
              ::before {
                text-indent: 15px;
                margin-right: 10px;
                font-weight: bold;
                content: "·";
              }
            }
          }
        }
      }
      &.ito-partner {
        .ito-partner-ul {
          padding: 20px 0;
          display: flex;
          flex-wrap: wrap;
          .main-contents-item {
            width: calc(100% / 4 - 20px);
            margin: 10px;
            display: flex;
            flex-direction: column;
            padding: 30px 10px;
            .img-area {
              width: 100%;
              aspect-ratio: 2/1;
              display: flex;
              border: 2px solid #999;
            }
            p {
              font-size: 20px;
              text-align: left;
            }
          }
        }
      }
    }
  }
  a {
    z-index: 1;
  }
  @media (max-width: 768px) {
    .scroll {
    }
    .main-contents {
      .main-contents-header {
        h1 {
          font-size: 20px;
          word-break: keep-all;
        }
      }
      .main-contents-body {
        margin: 30px 0;
        h1 {
          font-size: 20px;
        }
        ul {
          .main-contents-item {
            transition: 0.3s;
          }
        }
        &.ito-business {
          .ito-business-ul-box {
            display: unset;
            padding: unset;
            ul {
              width: 100%;
            }
            .ito-business-ul-1 {
              li {
                .main-contents-item-icon {
                  width: 36px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
            .ito-business-ul-2 {
              margin-left: unset;
              font-size: 14px;
            }
          }
        }
        &.ito-partner {
          .ito-partner-ul {
            padding: unset;
            margin-top: 20px;
            .main-contents-item {
              width: calc(100% / 2);
              margin: unset;
              padding: 10px;
              .img-area {
                svg {
                  margin: auto !important;
                }
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    a {
      z-index: 1;
    }
  }
`;
const BusinessItMain = ({ t, setIsPopup }: any) => {
  return (
    <BusinessItMainStyle
      main={data[0].head.color.main}
      active={data[0].head.color.active}
      hover={data[0].head.color.hover}
      background={data[0].head.color.background}
    >
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-header">
            <h1>{t(data[0].head.description[0])}</h1>
            {/* <button
              className="pointer"
              onClick={() => {
                setIsPopup(true);
              }}
            >
              <p>{t("csButton_1")}</p>
            </button> */}
          </article>
          <article className="main-contents-body ito-business">
            <h1>
              <p>{t(data[0].item[0].title)}</p>
            </h1>
            <div className="ito-business-ul-box">
              <ul className="ito-business-ul-1">
                {data[0].item[0].subList?.map((res2, idx) => {
                  return (
                    <li key={idx} className="main-contents-item">
                      <span className="main-contents-item-icon">
                        {data2[idx]}
                      </span>
                      <p>{t(res2)}</p>
                    </li>
                  );
                })}
              </ul>
              <ul className="ito-business-ul-2">
                <h2>{t(data[0].item[0].subList2_title)}</h2>
                {data[0].item[0].subList2?.map((res2, idx) => {
                  return (
                    <li key={idx} className="main-contents-item">
                      <p>{t(res2)}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>
          <article className="main-contents-body ito-partner">
            <h1>
              <p>{t(data[0].item[1].title)}</p>
            </h1>
            <ul className="ito-partner-ul">
              {data[0].item[1].itemList?.map((res2, idx) => {
                return (
                  <li key={idx} className="main-contents-item">
                    <div className="img-area">{res2.itemImg}</div>
                    <p>{t(res2.name)}</p>
                  </li>
                );
              })}
            </ul>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessItIto = ({ t }: any) => {
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState("service");
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <>
      <BusinessItItoStyle className="no-select">
        <BusinessItItoMain />
        <BusinessItMain t={t} setIsPopup={setIsPopup} />
      </BusinessItItoStyle>
      <AnimatePresence>
        {isPopup && (
          <Modal isPopup={isPopup} setIsPopup={setIsPopup} type={type}></Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BusinessItIto;
