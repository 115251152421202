import { Fragment, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";
import Popup from "./Popup";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}
const data = [
  {
    head: {
      title: "AI Factory Starter Kit",
      color: {
        main: "#880194",
        hover: "#b120be",
        active: "#590161",
        background: "#F6F7FF",
      },
    },
    category: "Hardware",
    item: [
      {
        title: "Server",
        product: "PowerEdge",
        itemList: [
          {
            name: "R760",
            description: "ai_1_con_1_des",
            spec: [
              {
                cpu: "Xeon Gold 6548Y+ 32C * 2ea",
                mem: "DDR5 384GB / 768GB",
                gpu: "NVIDIA L4",
              },
            ],
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/ai-1-1.png')" }} />
            ),
            itemGpuImg: (
              <div style={{ backgroundImage: "url('/icons/ai-1-2.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760/asper760  ",
          },
          {
            name: "R760XA",
            description: "ai_1_con_1_des",
            spec: [
              {
                cpu: "Xeon Platinum 8568Y+ 48C * 2ea",
                mem: "DDR5 512GB / 1TB",
                gpu: "NVIDIA L40S * 2ea",
              },
            ],
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/ai-2-1.png')" }} />
            ),
            itemGpuImg: (
              <div
                style={{
                  backgroundImage: "url('/icons/ai-2-2.png')",
                  backgroundSize: "75%",
                }}
              />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
          },
        ],
      },
      {
        title: "Workstation",
        product: "Precision",
        itemList: [
          {
            name: "5860T",
            description: "ai_2_con_1_des",
            spec: [
              {
                cpu: "Xeon W5-2465X 16C * 1ea",
                mem: "DDR5 256GB / 512GB",
                gpu: "NVIDIA A2000",
              },
            ],
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/ai-3-1.png')" }} />
            ),
            itemGpuImg: (
              <div style={{ backgroundImage: "url('/icons/ai-3-2.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/desktops/5860/spd/precision-5860-workstation",
          },
          {
            name: "7960T",
            description: "ai_2_con_2_des",
            spec: [
              {
                cpu: "Xeon W7-3465X 28C * 1ea",
                mem: "DDR5 512GB / 1TB",
                gpu: "NVIDIA A4000 ADA",
              },
            ],
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/ai-4-1.png')" }} />
            ),
            itemGpuImg: (
              <div
                style={{
                  backgroundImage: "url('/icons/ai-4-2.png')",
                  backgroundSize: "110%",
                }}
              />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/desktops/7960/spd/precision-t7960-workstation",
          },
        ],
      },
    ],
  },
  {
    head: {
      title: "ai_header_2",
      color: {
        main: "#880194",
        hover: "#b120be",
        active: "#590161",
        background: "#F6F7FF",
      },
    },
    category: "SCGS Gen AI Validated System",
  },
];
const logoData = [
  {
    os: {
      itemList: [
        { name: "Red Hat", image: <Icons.BrandRedHat /> },
        { name: "ubuntu", image: <Icons.BrandUbuntu /> },
        { name: "Rocky Linux", image: <Icons.BrandRockyLinux /> },
        { name: "SUSE", image: <Icons.BrandSuse /> },
      ],
    },
    tool: {
      itemList: [
        { name: "Cuda", image: <Icons.BrandCuda /> },
        { name: "Memcheck", image: <Icons.BrandMemcheck /> },
        {
          name: "cuDNN",
          image: (
            <div
              style={{
                backgroundImage: "url('/icons/brand-cudnn.png')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            />
          ),
        },
        { name: "python", image: <Icons.BrandPython /> },
      ],
    },
    framework: {
      itemList: [
        { name: "TensorFlow", image: <Icons.BrandTensorflow /> },
        { name: "PyTorch", image: <Icons.BrandPytorch /> },
        { name: "Keras", image: <Icons.BrandKeras /> },
        { name: "Caffe2", image: <Icons.BrandCaffe2 /> },
      ],
    },
  },
];
const gpuDataList = [
  {
    case: "ai_p2_gpuDataList_1_case",
    detail: [
      "NLP(Natural Language Processing)",
      "LLM(Large Language Model)",
      "HPC(High Performance Computing)",
      "ai_p2_gpuDataList_1_detail_1",
      "ai_p2_gpuDataList_1_detail_2",
    ],
    mainLink:
      "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-xe9680/aspexe9680",

    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/XE9680.png')" }} />,
    modelDetail: [
      {
        modelName: "XE9680",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-xe9680/aspexe9680",
      },
    ],
    spec: ["H100 SXM"],
  },
  {
    case: "ai_p2_gpuDataList_2_case",
    detail: [
      "HPC",
      "ai_p2_gpuDataList_2_detail_1",
      "ai_p2_gpuDataList_2_detail_2",
      "NLP",
      "ai_p2_gpuDataList_2_detail_3",
      "ai_p2_gpuDataList_2_detail_4",
      "ai_p2_gpuDataList_2_detail_5",
      "ai_p2_gpuDataList_2_detail_6",
    ],
    mainLink:
      "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-xe8640/aspexe8640",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/XE8640.png')" }} />,
    modelDetail: [
      {
        modelName: "XE8640",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-xe8640/aspexe8640",
      },
      {
        modelName: "XE9680",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-xe9680/aspexe9680",
      },
    ],
    spec: ["H100 SXM", "A100 SXM"],
  },
];
const aiDataList = [
  {
    case: "ai_p2_aiDataList_1_case",
    detail: [
      "ai_p2_aiDataList_1_detail_1",
      "ai_p2_aiDataList_1_detail_2",
      "CAD/CAM/CAE",
      "ai_p2_aiDataList_1_detail_3",
      "HPC",
    ],
    mainLink:
      "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/R760XA.png')" }} />,
    modelDetail: [
      {
        modelName: "R750",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750",
      },
      {
        modelName: "R7525",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
      },
      {
        modelName: "R750xa",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750xa",
      },
      {
        modelName: "R760xa",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
      },
    ],
    spec: ["L40", "A40"],
  },
  {
    case: "ai_p2_aiDataList_2_case",
    detail: [
      "HPC",
      "ai_p2_aiDataList_2_detail_1",
      "ai_p2_aiDataList_2_detail_2",
      "ai_p2_aiDataList_2_detail_3",
      "ai_p2_aiDataList_2_detail_4",
    ],
    mainLink:
      "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r960/asper960",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/R960.png')" }} />,
    modelDetail: [
      {
        modelName: "R750",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750",
      },
      {
        modelName: "R760",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760/asper760",
      },
      {
        modelName: "R7525",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
      },
      {
        modelName: "R7625",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r7625",
      },
      {
        modelName: "R750xa",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750xa",
      },
      {
        modelName: "R760xa",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
      },
      {
        modelName: "R960",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r960/asper960",
      },
    ],
    spec: ["A2", "A10", "A30", "A100", "L4"],
  },
  {
    case: "ai_p2_aiDataList_3_case",
    detail: ["ai_p2_aiDataList_3_detail_1", "ai_p2_aiDataList_3_detail_2"],
    mainLink:
      "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/R7525.png')" }} />,
    modelDetail: [
      {
        modelName: "R750",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750",
      },
      {
        modelName: "R760",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760/asper760",
      },
      {
        modelName: "R750xa",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750xa",
      },
      {
        modelName: "R760xa",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
      },
      {
        modelName: "R7525",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
      },
      {
        modelName: "R7625",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r7625",
      },
      {
        modelName: "R960",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r960/asper960",
      },
    ],
    spec: ["A10", "A16", "L4"],
  },
  {
    case: "ai_p2_aiDataList_4_case",
    detail: ["ai_p2_aiDataList_4_detail_1"],
    mainLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r7625",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/R7625.png')" }} />,
    modelDetail: [
      {
        modelName: "R750",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750",
      },
      {
        modelName: "R760",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760/asper760",
      },
      {
        modelName: "R7525",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
      },
      {
        modelName: "R7625",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r7625",
      },
    ],
    spec: ["A10", "L4"],
  },
  {
    case: "ai_p2_aiDataList_5_case",
    detail: [
      "ai_p2_aiDataList_5_detail_1",
      "ai_p2_aiDataList_5_detail_2",
      "ai_p2_aiDataList_5_detail_3",
    ],
    mainLink:
      "https://www.dell.com/ko-kr/shop/desktops/5860/spd/precision-5860-workstation",
    model: "Precision",
    mainImage: <div style={{ backgroundImage: "url('/icons/ai-3-1.png')" }} />,
    modelDetail: [
      {
        modelName: "5860T",
        modelLink:
          "https://www.dell.com/ko-kr/shop/desktops/5860/spd/precision-5860-workstation",
      },
      {
        modelName: "7960T",
        modelLink:
          "https://www.dell.com/ko-kr/shop/desktops/7960/spd/precision-t7960-workstation",
      },
    ],
    spec: ["A2000", "A4000ADA"],
  },
  {
    case: "ai_p2_aiDataList_6_case",
    detail: [
      "ai_p2_aiDataList_6_detail_1",
      "ai_p2_aiDataList_6_detail_2",
      "ai_p2_aiDataList_6_detail_3",
    ],
    mainLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-xr12",
    model: "PowerEdge",
    mainImage: <div style={{ backgroundImage: "url('/icons/XR12.png')" }} />,
    modelDetail: [
      {
        modelName: "XR12",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-xr12",
      },
      {
        modelName: "R750",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750",
      },
      {
        modelName: "R760",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760/asper760",
      },
      {
        modelName: "R750xa",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r750xa",
      },
      {
        modelName: "R760xa",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r760xa/asper760xa",
      },
      {
        modelName: "R7525",
        modelLink:
          "https://www.dell.com/ko-kr/shop/cty/pdp/spd/poweredge-r7525/per752510a",
      },
      {
        modelName: "R7625",
        modelLink: "https://www.dell.com/ko-kr/shop/ipovw/poweredge-r7625",
      },
    ],
    spec: ["A2", "L4"],
  },
];
const BusinessItAiStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const BusinessItAiMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-ai-1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessItAiMain = () => {
  return (
    <BusinessItAiMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            AI Solution
          </motion.h1>
        </motion.div>
      </div>
    </BusinessItAiMainStyle>
  );
};
const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.1s;
    .main-nav {
      line-height: 1.1em;
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: 0.5s;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
        :hover {
          color: #fff;
          background-color: ${(props) => props.active};
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .package-plus {
      display: flex;
      justify-content: center;
      position: relative;
      width: inherit;
      height: 50px;
      ::after,
      ::before {
        content: "";
        display: flex;
        background-color: ${(props) => props.main};
        width: 10px;
        height: 50px;
        position: absolute;
      }
      ::before {
        transform: rotate(90deg);
      }
    }
    .main-contents-body-border {
      border: 5px solid ${(props) => props.main};
      margin: 20px 0;
      padding: 10px;
    }
    .main-contents-header {
      padding: 30px 0;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 30px;
      }
    }
    .main-contents-body {
      h1 {
        margin-top: 10px;
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
      }
      h3 {
        padding-top: 5px;
        font-size: 24px;
        text-indent: 10px;
      }
      h4 {
        margin-bottom: 10px;
        color: ${(props) => props.main};
        font-size: 22px;
        text-align: center;
      }
      h5 {
        margin: 10px 0 10px 0;
        font-size: 20px;
      }
      &.main-item {
        h1 {
          width: 100%;
        }
        h2 {
          font-size: 20px;
          margin: 20px 0;
        }
        section {
          display: flex;
          flex-direction: row;
          width: 100%;
          article {
            width: calc(50% - 5px);
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            :first-child {
              .main-contents-item {
                background-color: ${(props) => props.background};
              }
            }
            > ul {
              display: flex;
              flex-wrap: wrap;
              max-width: 1000px;
              height: 100%;
              .main-contents-item {
                text-align: left;
                width: calc(100% / 2);
                display: flex;
                flex-direction: column;
                padding: 5px;
                transition: 0.3s;
                position: relative;
                .spec-area {
                  font-size: 14px;
                  display: flex;
                  flex-direction: column;
                  margin-top: auto;
                  li:first-of-type .spec-title {
                    min-height: 40px;
                  }
                  li {
                    display: flex;
                    margin-bottom: 1px;
                    .spec-title {
                      min-width: 60px;
                      background-color: ${(props) => props.main};
                      color: #fff;
                      padding: 5px 10px;
                    }
                    p {
                      color: #000;
                      text-align: center;
                    }
                    span {
                      margin: auto 0;
                      padding: 0 5px;
                    }
                  }
                }
                :hover {
                  transform: translate(-2px, -2px);
                  box-shadow: 2px 2px 5px 0 #00000022;
                }
                p {
                  font-size: 16px;
                  color: ${(props) => props.main};
                }
                .contents-img div {
                  aspect-ratio: 1;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 100%;
                }
              }
            }
          }
        }
        display: flex;
        flex-wrap: wrap;
      }
      .package-h2 {
        padding: 5px 20px;
        font-size: 26px;
        margin-top: 50px;
        background-color: ${(props) => props.main};
        color: rgb(255, 255, 255);
        width: fit-content;
      }
      .package-ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        text-align: left;
        font-weight: bold;
        margin: unset;
        li {
          width: calc(100% / 4 - (40px / 4));
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          .package-img-div {
            height: 100%;
            aspect-ratio: 3/1;
            border: 1px solid #ddd;
            display: flex;
            div {
              width: 100%;
              height: 100%;
            }
            svg {
              margin: auto;
            }
          }

          :nth-of-type(n) {
            margin-bottom: unset;
          }
          :nth-of-type(n + 4) {
            margin-right: unset;
          }
        }
      }
      .main-button-box {
        display: flex;
        position: absolute;
        right: calc(10px + 5%);
        bottom: calc(10px + 5%);
        button {
          display: flex;
          position: relative;
          border: 1px solid ${(props) => props.main};
          background-color: #ffffff66;
          transition: 0.3s;
          padding: 3px 10px;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
          }
          :first-child::after {
            background-color: ${(props) => props.main};
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            color: ${(props) => props.main};
            transition: inherit;
            margin: 2px auto auto auto;
            font-size: 12px;
            z-index: 1;
          }
        }
      }
      .package-p2-main-ul {
        flex-direction: column;
        text-align: left;
        max-width: 1200px;
        font-size: 18px;
        .package-p2-main-ul-li {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: ${(props) => props.background};
          :nth-of-type(2n-1) {
            background-color: unset;
          }
          :last-child .package-p2-border-bottom {
            border-bottom: unset;
          }
          .package-p2-border-bottom {
            border-bottom: 1px solid ${(props) => props.main};
            margin-top: 10px;
            padding-bottom: 5px;
            display: flex;
            > li {
              padding: 5px;
              flex: 1;
            }
            .package-p2-image-box {
              display: flex;
              position: relative;
              aspect-ratio: 1;
              max-width: 300px;
              > div {
                display: flex;
                margin: auto;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
            .package-p2-con-detail {
              .package-p2-con-detail-ul {
                .package-p2-con-detail-ul-li {
                  display: flex;
                  margin-bottom: 5px;
                  ::before {
                    content: "";
                    margin: auto 5px auto 0;
                    min-width: 6px;
                    min-height: 6px;
                    background-color: ${(props) => props.main};
                  }
                }
              }
            }
            .package-p2-con-model {
              .package-p2-con-model-ul {
                display: flex;
                flex-wrap: wrap;
                .package-p2-con-model-ul-li {
                  display: flex;
                  ::after {
                    content: " / ";
                    margin: auto 5px;
                  }
                  :last-child::after {
                    content: unset;
                  }
                  :first-child::before {
                    content: "";
                    margin: auto 5px auto 0;
                    min-width: 6px;
                    min-height: 6px;
                    background-color: ${(props) => props.main};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  a {
    z-index: 1;
  }
  .w-fill {
    width: 100% !important;
  }
  .mb-20 {
    margin-bottom: 20px !important;
  }
  @media (max-width: 768px) {
    .nav-header {
      font-size: 14px;
      height: 40px;
      line-height: 16px;
    }
    .main-contents {
      .main-contents-body {
        .package-h2 {
          font-size: 18px;
        }
        .package-ul {
          justify-content: center;
          li {
            min-width: 250px;
            margin: auto auto 20px !important;
            .package-img-div {
              aspect-ratio: 2/1;
              padding: unset;
              div {
                width: 100%;
                height: 100%;
              }
              svg {
                margin: auto;
              }
            }
            svg {
              height: 100%;
            }
            :nth-of-type(n + 4) {
              margin-bottom: unset;
            }
            :nth-of-type(3n + 1) {
              margin-left: unset;
            }
          }
        }
        .package-p2-main-ul {
          font-size: 14px;
          .package-p2-main-ul-li {
            .package-p2-border-bottom {
              display: block;
              > li {
                max-width: 400px;
                margin: 0 auto;
                flex: unset;
              }
            }
          }
        }

        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 20px;
          text-indent: 10px;
        }
        h4 {
          margin-bottom: 10px;
          color: ${(props) => props.main};
          font-size: 18px;
          text-align: center;
        }
        h5 {
          margin: 10px 0 10px 0;
          font-size: 16px;
        }
        &.main-item {
          section {
            flex-direction: column;
            article {
              width: 100%;
              > ul {
                .main-contents-item {
                  width: calc(100%);
                  max-width: 400px;
                  margin: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 20px;
                  transition: 0.3s;
                  justify-content: space-between;
                  :hover {
                    transform: translate(-2px, -2px);
                    box-shadow: 2px 2px 5px 0 #00000022;
                  }
                  .spec-area {
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    li {
                      display: flex;
                      flex-direction: column;
                      span {
                        padding: 10px;
                      }
                    }
                  }
                  h2 {
                    font-size: 18px;
                    margin-bottom: 20px;
                  }
                  p {
                    font-size: 16px;
                    padding: 10px 0;
                    margin-bottom: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = ({ t }: any) => {
  const [tab, setTab] = useState(Number);
  return (
    <BusinessItMainStyle
      main={data[tab].head.color.main}
      active={data[tab].head.color.active}
      hover={data[tab].head.color.hover}
      background={data[tab].head.color.background}
    >
      <div className="scroll">
        <header className="nav-header">
          <ul className="main-nav pointer">
            {data.map((res: any, idx: Number) => {
              return (
                <li
                  key={String(idx)}
                  style={{ position: "relative" }}
                  className={`${tab === idx ? "active" : ""} tab${idx}`}
                  onClick={() => {
                    setTab(Number(idx));
                  }}
                >
                  <p>{t(res.head.title)}</p>
                </li>
              );
            })}
          </ul>
        </header>
        <section className="main-contents">
          <article className="main-contents-header">
            <h1>{t("ai_main_title_1")}</h1>
            <h1>{t("ai_main_title_2")}</h1>
          </article>
          <div className={tab !== 1 ? `main-contents-body-border` : ""}>
            <article className="main-contents-body main-item">
              <h1>
                <p>{data[tab].category}</p>
              </h1>
              <section>
                {data[tab].item?.map((res: any, idx) => {
                  return (
                    <article key={idx}>
                      <h2 className="package-h2 w-fill">
                        <p>{t(res.title)}</p>
                      </h2>
                      {res.itemList && (
                        <ul>
                          {res.itemList.map(
                            (
                              res: {
                                name: string;
                                itemImg: React.ReactNode;
                                itemLink?: string | undefined;
                                description?: string;
                                spec?: any;
                                itemList?: any;
                              },
                              itemIdx: any
                            ) => {
                              return (
                                <li
                                  key={itemIdx}
                                  className="main-contents-item"
                                >
                                  <div className="contents-img">
                                    {res.itemImg}
                                  </div>
                                  <h2>
                                    <span>
                                      <span style={{ fontWeight: 400 }}>
                                        {data[0]?.item?.[idx].product}
                                      </span>
                                      <b> {t(res.name)}</b>
                                    </span>
                                    <p>{t(res.description)}</p>
                                  </h2>
                                  <ul className="spec-area">
                                    {res.spec.map(
                                      (keyRes: any, KeyIdx: string) => {
                                        return (
                                          <Fragment key={KeyIdx}>
                                            {Object.entries(keyRes).map(
                                              ([key, value]: any, specIdx) => {
                                                return (
                                                  <li key={specIdx}>
                                                    <p className="spec-title">
                                                      {key.toUpperCase()}
                                                    </p>
                                                    <span>{value}</span>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </ul>
                                  {res.itemLink && (
                                    <a target="_blank" href={res.itemLink}></a>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </article>
                  );
                })}
              </section>
            </article>
          </div>
          {tab !== 1 ? <div className="package-plus"></div> : ""}
          <div className={tab !== 1 ? `main-contents-body-border` : ""}>
            {tab === 0 && (
              <article className="main-contents-body main-item">
                <h1>
                  <p>Software</p>
                </h1>
                {logoData.map((res, idx) => {
                  return (
                    <Fragment key={idx}>
                      <h2 className="package-h2">
                        <p>Operating System</p>
                      </h2>
                      <ul className="package-ul">
                        {res.os.itemList.map((resOs, idxOs) => {
                          return (
                            <li key={idxOs}>
                              <div className="package-img-div">
                                {resOs.image}
                              </div>
                              {/* <p>{resOs.name}</p> */}
                            </li>
                          );
                        })}
                      </ul>
                      <h2 className="package-h2">
                        <p>Toolkit & Library</p>
                      </h2>
                      <ul className="package-ul">
                        {res.tool.itemList.map((resTool, idxTool) => {
                          return (
                            <li key={idxTool}>
                              <div className="package-img-div">
                                {resTool.image}
                              </div>
                              {/* <p>{resTool.name}</p> */}
                            </li>
                          );
                        })}
                      </ul>
                      <h2 className="package-h2">
                        <p>AI/ML Framework</p>
                      </h2>
                      <ul className="package-ul">
                        {res.framework.itemList.map(
                          (resFramework, idxFrame) => {
                            return (
                              <li key={idxFrame}>
                                <div className="package-img-div">
                                  {resFramework.image}
                                </div>
                                {/* <p>{resFramework.name}</p> */}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </Fragment>
                  );
                })}
              </article>
            )}
            {tab === 1 && (
              <article className="main-contents-body">
                <h2 className="package-h2 mb-20">{t("ai_p2_header_1")}</h2>
                <ul className="package-p2-main-ul">
                  {gpuDataList.map((res, idx) => {
                    return (
                      <li className="package-p2-main-ul-li" key={idx}>
                        <h3>{t(res.case)}</h3>
                        <ul className="package-p2-border-bottom">
                          <li className="package-p2-image-box">
                            {res.mainImage}
                            <span className="main-button-box">
                              <button>
                                <p>{t("more")}</p>
                                <Link to={res.mainLink} target="_blank" />
                              </button>
                            </span>
                          </li>
                          <li className="package-p2-con-detail">
                            <h4>{t("ai_p2_li_header_1")}</h4>
                            <ul className="package-p2-con-detail-ul">
                              {res.detail.map((listData, listIdx) => {
                                return (
                                  <li
                                    key={listIdx}
                                    className="package-p2-con-detail-ul-li"
                                  >
                                    {t(listData)}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                          <li className="package-p2-con-model">
                            <h4>{t("ai_p2_li_header_2")}</h4>
                            <h5>{res.model}</h5>
                            <ul className="package-p2-con-model-ul">
                              {res.modelDetail.map((model, modelIdx) => {
                                return (
                                  <li
                                    key={modelIdx}
                                    className="package-p2-con-model-ul-li"
                                  >
                                    {model.modelName}
                                  </li>
                                );
                              })}
                            </ul>
                            <h5>GPU</h5>
                            <ul className="package-p2-con-model-ul">
                              {res.spec.map((specList, specListIdx) => {
                                return (
                                  <li
                                    key={specListIdx}
                                    className="package-p2-con-model-ul-li"
                                  >
                                    {specList}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
                </ul>

                <h2 className="package-h2 mb-20">{t("ai_p2_header_2")}</h2>
                <ul className="package-p2-main-ul">
                  {aiDataList.map((res, idx) => {
                    return (
                      <li className="package-p2-main-ul-li" key={idx}>
                        <h3>{t(res.case)}</h3>
                        <ul className="package-p2-border-bottom">
                          <li className="package-p2-image-box">
                            {res.mainImage}
                            <span className="main-button-box">
                              <button>
                                <p>{t("more")}</p>
                                <Link to={res.mainLink} target="_blank" />
                              </button>
                            </span>
                          </li>
                          <li className="package-p2-con-detail">
                            <h4>{t("ai_p2_li_header_1")}</h4>
                            <ul className="package-p2-con-detail-ul">
                              {res.detail.map((listData, listIdx) => {
                                return (
                                  <li
                                    key={listIdx}
                                    className="package-p2-con-detail-ul-li"
                                  >
                                    {t(listData)}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                          <li className="package-p2-con-model">
                            <h4>{t("ai_p2_li_header_2")}</h4>
                            <h5>{res.model}</h5>
                            <ul className="package-p2-con-model-ul">
                              {res.modelDetail.map((model, modelIdx) => {
                                return (
                                  <li
                                    key={modelIdx}
                                    className="package-p2-con-model-ul-li"
                                  >
                                    {model.modelName}
                                  </li>
                                );
                              })}
                            </ul>
                            <h5>GPU</h5>
                            <ul className="package-p2-con-model-ul">
                              {res.spec.map((specList, specListIdx) => {
                                return (
                                  <li
                                    key={specListIdx}
                                    className="package-p2-con-model-ul-li"
                                  >
                                    {specList}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </article>
            )}
          </div>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessItAi = ({ t, i18n }: any) => {
  return (
    <>
      <BusinessItAiStyle className="no-select">
        <BusinessItAiMain />
        <BusinessItMain t={t} />
        {i18n.language === "ko" ? <Popup /> : <></>}
      </BusinessItAiStyle>
    </>
  );
};

export default BusinessItAi;
