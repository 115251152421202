import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import Modal from "./Modal";
import API from "../api/api";
import Paging from "./Pagenation";
import Icons from "../assets/icons";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}
const data = {
  head: {
    title: "건물종합관리",
    color: {
      main: "#0CA69D",
      hover: "#25a69d",
      active: "#008a81",
      background: "#F9FDFD",
    },
    type: "service",
    description: ["buildingDescription", "buildingDescription_1"],
  },
  item: {
    title: "building_1_title",
    pageLink: "https://www.dell.com/ko-kr/dt/servers/index.htm",
    subject: "building_1_subject",
    description: "building_1_des",
    itemList: [
      {
        name: "building_1_con_1_title",
        itemImg: (
          <div
            style={{ backgroundImage: 'url("/background/managing-bg1.jpg")' }}
          />
        ),
        subList: [
          "building_1_con_1_1",
          "building_1_con_1_2",
          "building_1_con_1_3",
          "building_1_con_1_4",
          "building_1_con_1_5",
        ],
      },
      {
        name: "building_1_con_2_title",
        itemImg: (
          <div
            style={{ backgroundImage: 'url("/background/managing-bg2.jpg")' }}
          />
        ),
        subList: [
          "building_1_con_2_1",
          "building_1_con_2_2",
          "building_1_con_2_3",
          "building_1_con_2_4",
          "building_1_con_2_5",
          "building_1_con_2_6",
          "building_1_con_2_7",
        ],
      },
      {
        name: "building_1_con_3_title",
        itemImg: (
          <div
            style={{ backgroundImage: 'url("/background/managing-bg3.jpg")' }}
          />
        ),
        subList: [
          "building_1_con_3_1",
          "building_1_con_3_2",
          "building_1_con_3_3",
          "building_1_con_3_4",
          "building_1_con_3_5",
        ],
      },
      {
        name: "building_1_con_4_title",
        itemImg: (
          <div
            style={{ backgroundImage: 'url("/background/managing-bg4.jpg")' }}
          />
        ),
        subList: [
          "building_1_con_4_1",
          "building_1_con_4_2",
          "building_1_con_4_3",
          "building_1_con_4_4",
          "building_1_con_4_5",
        ],
      },
      {
        name: "building_1_con_5_title",
        itemImg: (
          <div style={{ backgroundImage: 'url("/icons/ev-bg6.jpg")' }} />
        ),
        subList: [
          "building_1_con_5_1",
          "building_1_con_5_2",
          "building_1_con_5_3",
        ],
      },
    ],
  },
};
const data2 = {
  head: {
    title: "ev_title",
    type: "service",
    process: <Icons.EvBg1 />,
    process2: <Icons.EvBg2 />,
    subject: "ev_sub_title",
    description: "ev_description",
  },
  imgList: [
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg1.jpg")' }} />,
    },
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg2.jpg")' }} />,
    },
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg3.jpg")' }} />,
    },
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg4.jpg")' }} />,
    },
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg5.jpg")' }} />,
    },
    {
      itemImg: <div style={{ backgroundImage: 'url("/icons/ev-bg6.jpg")' }} />,
    },
  ],
};
const BusinessManagingStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessManagingMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-managing.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        margin: auto;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessManagingMain = ({ t }: any) => {
  return (
    <BusinessManagingMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("mainManage")}
          </motion.h1>
        </motion.div>
      </div>
    </BusinessManagingMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.3s;
    .main-nav {
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: inherit;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .main-contents-header {
      padding: 30px 0;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      h1 {
        letter-spacing: -1px;
        font-size: 30px;
      }
      button {
        display: flex;
        width: 150px;
        height: 40px;
        margin: 20px auto 0;
        border: 1px solid ${(props) => props.main};
        transition: 0.3s;
        position: relative;
        ::after {
          transition: inherit;
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          background-color: ${(props) => props.main};
          top: 0;
          left: 0;
          z-index: 0;
        }
        :hover {
          ::after {
            width: 100%;
          }
          p {
            color: #fff;
          }
        }
        p {
          transition: inherit;
          margin: auto;
          font-size: 14px;
          z-index: 1;
        }
      }
    }
    .main-contents-body {
      margin: 100px 0;
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
        aside {
          margin: auto 5px auto auto;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: ${(props) => props.main};
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      header {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        b {
          font-size: 26px;
        }
        span {
          margin-top: 5px;
        }
      }
      .main-ul {
        display: flex;
        margin: auto;
        max-width: 1200px;
        padding: 30px 0;
        .main-contents-item {
          word-break: keep-all;
          & + .main-contents-item {
            margin-left: 20px;
          }
          width: calc(100%);
          display: flex;
          flex-direction: column;
          transition: 0.3s;
          .sub-ul {
            margin-top: 15px;
            .sub-li {
              display: flex;
              text-align: left;
              p {
                font-size: 16px;
                margin: auto 0;
              }
              ::before {
                text-indent: 15px;
                margin: 0;
                margin-right: 10px;
                font-weight: bold;
                content: "·";
              }
            }
          }
          :hover {
            transform: translate(-2px, -2px);
            box-shadow: 2px 2px 5px 0 #00000022;
            .contents-img {
              .background {
                opacity: 0;
              }
              div {
                aspect-ratio: 1;
                background-color: ${(props) => props.background};
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }
          h2 {
            font-size: 26px;
          }
          .contents-img {
            position: relative;
            .background {
              transition: 0.3s;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #2d59a2cc;
              display: flex;
              h2 {
                position: relative;
                color: #fff;
                z-index: 1;
                margin: auto;
              }
            }
            div {
              aspect-ratio: 1;
              background-color: ${(props) => props.background};
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
      .article-title {
        padding: 10px 30px;
        margin-top: 30px;
        background-color: ${(props) => props.main};
        color: #fff;
        width: fit-content;
      }
      .performance-ul {
        text-align: left;
        flex-wrap: wrap;
        display: flex;
        margin: 20px 0;
        .performance-li {
          display: flex;
          width: 50%;
          transition: 0.3s;
          padding: 10px 0;
          :hover {
            transform: translate(-2px, -2px);
            box-shadow: 2px 2px 5px 0 #00000022;
          }
          .performance-image {
            margin: auto 0;
            height: auto;
            width: 100%;
            max-width: 210px;
            max-height: 140px;
            aspect-ratio: 3/2;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .performance-info {
            padding: 0px 20px;
            width: 100%;
            h3 {
              position: relative;
              display: flex;
              padding: 0 0 10px 0;
              ::before {
                content: "";
                width: 5px;
                height: 20px;
                background-color: ${(props) => props.main};
                margin: auto 5px auto 0;
              }
            }
            li {
              display: flex;
              font-size: 18px;
              margin-left: 10px;
              b {
                min-width: 70px;
                display: flex;
                justify-content: space-between;
                font-weight: normal;
                margin-right: 5px;
              }
              i {
                font-style: unset;
              }
              span {
              }
            }
          }
        }
      }
      .process-banner {
        display: flex;
        width: 100%;
        margin: 30px 0;
        svg {
          width: 100%;
          height: auto;
        }
      }
      .process-ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: auto;
        .process-li {
          width: calc(100% / 3 - 10px / 3);
          margin-right: 5px;
          margin-bottom: 5px;
          :nth-of-type(3n) {
            margin-right: 0px;
          }
          .contents-img {
            aspect-ratio: 3/2;
            display: flex;
            transition: 0.3s;
            :hover {
              transform: translate(-2px, -2px);
              box-shadow: 2px 2px 5px 0 #00000088;
            }
            > div {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main-contents {
      .main-contents-header {
        padding: 30px 0;
        h1 {
          letter-spacing: -1px;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      .main-contents-body {
        h1 {
          font-size: 20px;
        }
        header {
          b {
            font-size: 18px;
          }
        }
        .main-ul {
          padding: unset;
          margin: 30px 0;
          display: block;
          pointer-events: none;
          .main-contents-item {
            width: calc(calc(100%));
            & + .main-contents-item {
              margin-left: unset;
              margin-top: 20px;
            }
            &:nth-of-type(2n + 1) {
              margin-left: unset;
            }
            .sub-ul {
              margin-top: 5px;
              .sub-li {
                p {
                  font-size: 14px;
                }
                ::before {
                  text-indent: 5px;
                  margin-right: 5px;
                }
              }
            }
            h2 {
              font-size: 20px;
            }
            .contents-img {
              display: flex;
              width: 100%;
              max-width: 100%;
              > div {
                width: 50%;
              }
              .background {
                position: relative;
                width: 50%;
                aspect-ratio: 1;
                background-color: #2d59a2;
              }
            }
          }
        }
        .performance-ul {
          .performance-li {
            width: 100%;
            .performance-image {
              max-width: 120px;
              max-height: 80px;
            }
            .performance-info {
              padding: 5px 0px;
              margin-left: 10px;
              li {
                font-size: 16px;
                margin-left: unset;
              }
            }
          }
        }
        .process-banner {
          /* svg {
            transform: scale(1.2);
          } */
        }
        .process-ul {
          .process-li {
            width: calc((100% - 5px) / 2);
            :nth-of-type(2n) {
              margin-right: 0px;
            }
            :nth-of-type(3) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = ({
  i18n,
  t,
  lastPageNum,
  pageNum,
  setPageNum,
  dataList,
  setIsPopup,
}: any) => {
  return (
    <BusinessItMainStyle
      main={data.head.color.main}
      active={data.head.color.active}
      hover={data.head.color.hover}
      background={data.head.color.background}
    >
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-header">
            <h1>
              {t(data.head.description[0])}
              <br></br>
              {t(data.head.description[1])}
            </h1>
            {i18n.language === "ko" && (
              <button
                className="pointer"
                onClick={() => {
                  setIsPopup(true);
                }}
              >
                <p>{t("csButton_1")}</p>
              </button>
            )}
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t(data.item.title)}</p>
            </h1>
            <header>
              <b>{t(data.item.subject)}</b>
              <span>{t(data.item.description)}</span>
            </header>
            <ul className="main-ul">
              {data.item.itemList.map((res2, idx) => {
                return (
                  <li key={idx} className="main-contents-item main-li">
                    <div className="contents-img">
                      <span className="background">
                        <h2>{t(res2.name)}</h2>
                      </span>
                      {res2.itemImg}
                    </div>
                    <ul className="sub-ul">
                      {res2.subList.map((subList, idx) => {
                        return (
                          <li className="sub-li" key={idx}>
                            <p>{t(subList)}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            {i18n.language === "ko" && (
              <>
                <h2 className="article-title">
                  <p>{t("building_2_title")}</p>
                </h2>
                <ul className="performance-ul">
                  {dataList.map((res: any, idx: number) => {
                    return (
                      <li className="performance-li" key={idx}>
                        <div
                          className="performance-image"
                          style={{
                            backgroundImage: `url(${
                              res.imageList && res.imageList !== "[]"
                                ? res.imageList.split(/[[\]]/).join("")
                                : `/logo_sm_color.svg`
                            })`,
                          }}
                        ></div>
                        <ul className="performance-info">
                          <h3>
                            <p>{res.title}</p>
                          </h3>
                          <li>
                            <b>
                              {t("buildingLocation")}
                              <i>:</i>
                            </b>
                            <span>{res.place}</span>
                          </li>
                          <li>
                            <b>
                              {t("buildingArea")}
                              <i>:</i>
                            </b>
                            <span>{res.area}</span>
                          </li>
                          <li>
                            <b>
                              {t("buildingOtherDetails")}
                              <i>:</i>
                            </b>
                            <span>{res.discription}</span>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
                </ul>
                {Paging({
                  lastPageNum: lastPageNum,
                  pageNum: pageNum,
                  setPageNum: setPageNum,
                })}
              </>
            )}
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t(data2.head.title)}</p>
            </h1>
            <header>
              <b>{t(data2.head.subject)}</b>
              <span>{t(data2.head.description)}</span>
            </header>
            <h2 className="article-title">
              <p>{t("ev_process_title")}</p>
            </h2>
            {i18n.language === "ko" ? (
              <div className="process-banner">{data2.head.process}</div>
            ) : (
              <div className="process-banner">{data2.head.process2}</div>
            )}
            <ul className="process-ul">
              {data2.imgList.map((res2, idx) => {
                return (
                  <li key={idx} className="process-li">
                    <div className="contents-img">{res2.itemImg}</div>
                  </li>
                );
              })}
            </ul>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};
const BusinessManaging = ({ t, i18n }: any) => {
  const [dataList, setDataList] = useState([]);
  const [pageNum, setPageNum] = useState(Number);
  const [lastPageNum, setLastPageNum] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState("service");
  useEffect(() => {
    API.BOARD_LIST({
      setDataList: setDataList,
      boardName: "Board_BuildingMng",
      setPageNum: setPageNum,
      setLastPageNum: setLastPageNum,
      pageNum: pageNum,
    });
  }, [pageNum, lastPageNum]);
  return (
    <>
      <BusinessManagingStyle className="no-select">
        <BusinessManagingMain t={t} />
        <BusinessItMain
          i18n={i18n}
          t={t}
          lastPageNum={lastPageNum}
          pageNum={pageNum}
          setPageNum={setPageNum}
          dataList={dataList}
          setIsPopup={setIsPopup}
        />
      </BusinessManagingStyle>
      <AnimatePresence>
        {isPopup && (
          <Modal isPopup={isPopup} setIsPopup={setIsPopup} type={type}></Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BusinessManaging;
