import { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";

const TextList = [
  {
    class: "business-it-solution",
    title: "IT Solution",
    link: ["/business/it/solution/dell"],
    icons: <Icons.IconNetwork />,
  },
  {
    class: "business-ito",
    title: "ITO",
    link: ["/business/it/ito"],
    icons: <Icons.IconManage />,
  },
  {
    class: "business-ai",
    title: "AI Solution",
    link: ["/business/it/ai"],
    icons: <Icons.IconManage />,
  },
];

const BusinessItStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;

const BusinessItMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-it.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessItMain = ({ t }: any) => {
  return (
    <BusinessItMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("mainItBusiness")}
            {/* {t("mainIt")} */}
          </motion.h1>
        </motion.div>
      </div>
    </BusinessItMainStyle>
  );
};
const BusinessMainStyle = styled.div`
  position: relative;
  color: #fff;
  text-align: center;
  .scroll {
    max-width: 1200px;
    width: 95%;
    min-height: unset;
    height: 600px;
    margin: 30px auto 100px;
  }
  h1 {
    font-size: 50px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    word-break: keep-all;
  }
  ul {
    display: flex;
    height: 100%;
    &:hover {
      article {
        filter: grayscale();
        opacity: 0.5;
      }
      article:hover {
        filter: unset;
        opacity: 1;
        width: 100%;
      }
    }
    article {
      height: 100%;
      width: calc(100% / 3);
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      position: relative;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      header {
        margin-top: 70px;
        h2 {
          transition: 0.3s;
          font-size: 30px;
          margin: 20px 0;
        }
      }
    }
    .business-it-solution {
      background-color: #32415f;
      background-image: linear-gradient(to top, #32415f00 0%, #244389 80%),
        url("/background/business-solution.jpg");
    }
    .business-ito {
      background-color: #744c21;
      background-image: linear-gradient(to top, #744c2100 0%, #1a1041 80%),
        url("/background/business-ito.jpg");
    }
    .business-ai {
      background-color: #920eb3;
      background-image: linear-gradient(to top, #2cd3fd00 0%, #440c53 80%),
        url("/background/business-ai-1.jpg");
    }
    .net-1,
    .solar-1,
    .solar-2,
    .manage-1 {
      stroke: #fff;
    }
    .cs-1,
    .cs-2 {
      stroke: #000;
    }
  }
  button {
    display: flex;
    margin: 20px auto 0;
    border: 1px #ffffff solid;
    background: #ffffff66;
    border-radius: 2px;
    color: #fff;
    transition: 0.3s;
    a {
      padding: 2px 20px;
    }
    &:hover {
      background: #ffffff;
      color: #000;
    }
  }
  @media (max-width: 768px) {
    .scroll {
      height: unset;
      margin: 30px auto 50px;
    }
    h1 {
      padding: 20px 0;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      z-index: unset;
      color: #000;
    }
    ul {
      height: unset;
      flex-direction: column;
      &:hover {
        article {
          filter: unset;
          opacity: unset;
        }
        article:hover {
          width: unset;
        }
      }

      article {
        width: 100%;
        margin-bottom: 2.5%;
        header {
          margin-top: unset;
          min-height: 150px;
          height: calc((100vh - 70px) / 4);
          display: flex;
          h2 {
            margin: auto;
            font-size: 25px;
          }
        }
      }
    }
  }
`;
const BusinessMain = () => {
  return (
    <BusinessMainStyle>
      <div className="scroll">
        <ul>
          {TextList.map((res, idx) => {
            return (
              <article className={`${res.class}`} key={idx}>
                <header>
                  <h2>{res.title}</h2>
                </header>
                <Link to={res.link[0]}></Link>
              </article>
            );
          })}
        </ul>
      </div>
    </BusinessMainStyle>
  );
};

const BusinessIt = ({ t }: any) => {
  const up1 = useRef(null);
  const up2 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1, up2].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <BusinessItStyle className="no-select">
      <BusinessItMain t={t} />
      <section className="up-soon" ref={up2}>
        <BusinessMain />
      </section>
    </BusinessItStyle>
  );
};

export default BusinessIt;
