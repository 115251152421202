import { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "./Modal";
import Icons from "../assets/icons";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}
const data = {
  head: {
    color: {
      main: "#BE005B",
      hover: "#d81f78",
      active: "#99004a",
      background: "#fffcfe",
    },
    process: <Icons.CsBg1 />,
    process2: <Icons.CsBg2 />,
    type: "service",
    description: ["csDescription", "csDescription_1"],
  },
  item: [
    {
      title: "cs_4_title",
      titleDescription: "cs_4_des",
      subItemList: ["cs_4_subDes_1"],
      itemList: [
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg1.jpg")' }} />
          ),
        },
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg2.jpg")' }} />
          ),
        },
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg3.jpg")' }} />
          ),
        },
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg4.jpg")' }} />
          ),
        },
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg5.jpg")' }} />
          ),
        },
        {
          itemImg: (
            <div style={{ backgroundImage: 'url("/icons/call-bg6.jpg")' }} />
          ),
        },
      ],
    },
    {
      title: "cs_3_title",
      titleDescription: "cs_3_des",
      subItemList: ["cs_3_subDes_1", "cs_3_subDes_2"],
      itemList: [
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg1.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg2.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg3.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg4.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg5.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/cscenter-bg6.jpg")' }}
            />
          ),
        },
      ],
    },
    {
      title: "cs_1_title",
      titleDescription: "cs_1_des",
      subItemList: ["cs_1_subDes_1"],
      itemList: [
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg1.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg2.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg3.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg4.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg5.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/construction-bg6.jpg")' }}
            />
          ),
        },
      ],
    },
    {
      title: "cs_2_title",
      titleDescription: "cs_2_des",
      subItemList: ["cs_2_subDes_1", "cs_2_subDes_2"],
      itemList: [
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg1.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg2.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg3.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg4.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg5.jpg")' }}
            />
          ),
        },
        {
          itemImg: (
            <div
              style={{ backgroundImage: 'url("/icons/resource-bg6.jpg")' }}
            />
          ),
        },
      ],
    },
  ],
};
const data2 = {
  itemList: [
    {
      logo: <Icons.BrandSeoulgas3 />,
      itemSubject: "cs_4_con_2_1_title",
    },
    {
      logo: <Icons.BrandIncheongas />,
      itemSubject: "cs_4_con_2_2_title",
    },
    {
      logo: <Icons.BrandJbcorp2 height={35} />,
      itemSubject: "cs_4_con_2_3_title",
    },
  ],
  infoImg: (
    <div style={{ backgroundImage: 'url("/background/support-sub1.jpg")' }} />
  ),
};
const data3 = [
  {
    name: "cs_4_con_3_1_name",
    address: "cs_4_con_3_1_address",
  },
  {
    name: "cs_4_con_3_2_name",
    address: "cs_4_con_3_2_address",
  },
  {
    name: "cs_4_con_3_3_name",
    address: "cs_4_con_3_3_address",
  },
  {
    name: "cs_4_con_3_4_name",
    address: "cs_4_con_3_4_address",
  },
  {
    name: "cs_4_con_3_5_name",
    address: "cs_4_con_3_5_address",
  },
  {
    name: "cs_4_con_3_6_name",
    address: "cs_4_con_3_6_address",
  },
];

const BusinessSupportStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessSupportMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-support.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessSupportMain = ({ t }: any) => {
  return (
    <BusinessSupportMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("mainCs")}
          </motion.h1>
        </motion.div>
      </div>
    </BusinessSupportMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.3s;
    .main-nav {
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: inherit;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .main-contents-header {
      padding: 50px 0px;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 30px;
      }
    }

    .main-contents-body {
      margin: 100px 0;
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
      }
      .sub-list-box {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 50px;
        b {
          font-size: 26px;
          margin-bottom: 10px;
        }
        .sub-list {
          display: flex;
          text-align: left;
          ::before {
            text-indent: 5px;
            margin: auto 0;
            margin-right: 10px;
            font-weight: bold;
            content: "·";
          }
        }
      }
      .article-title {
        padding: 10px 30px;
        margin-top: 50px;
        background-color: ${(props) => props.main};
        color: #fff;
        width: fit-content;
      }
      .cs-banner {
        display: flex;
        max-width: 1000px;
        width: 100%;
        margin: 30px auto;
        svg {
          width: 100%;
          height: auto;
        }
      }
      .cs-image-ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: auto;
        .cs-image-li {
          width: calc(100% / 3 - 10px / 3);
          margin-right: 5px;
          margin-bottom: 5px;
          :nth-of-type(3n) {
            margin-right: 0px;
          }
          .contents-img {
            aspect-ratio: 3/2;
            display: flex;
            transition: 0.3s;
            :hover {
              transform: translate(-2px, -2px);
              box-shadow: 2px 2px 5px 0 #00000088;
            }
            > div {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
      .cs-status-ul {
        display: flex;
        margin: 20px auto;
        max-width: 1000px;
        text-align: left;
        width: 100%;
        .cs-status-li {
          width: 100%;
          & + li {
            margin-left: 20px;
          }
          .logo-box {
            border: 1px solid #ddd;
            aspect-ratio: 3/1;
            display: flex;
            height: auto;
            padding: 10px;
            svg {
              width: 100%;
              margin: auto;
            }
          }
          h3 {
            margin: 3px 0;
          }
          p {
            font-size: 18px;
          }
        }
      }
      .cs-info-image-area {
        display: flex;
        margin-top: 50px;
        .cs-info-image {
          display: flex;
          aspect-ratio: 16/9;
          margin-right: auto;
          width: 100%;
          max-width: 600px;
          div {
            height: 100%;
            width: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .cs-info-image-area-ul {
          text-align: left;
          width: 100%;
          max-width: 550px;
          .cs-info-image-area-li {
            h3 {
              display: flex;
              font-size: 18px;
              ::before {
                content: "";
                width: 4px;
                height: 18px;
                margin: auto 5px auto 0;
                background-color: #666;
              }
            }
            p {
              font-size: 16px;
              text-indent: 10px;
            }
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-contents {
      .main-contents-header {
        h1 {
          font-size: 20px;
        }
      }
      .main-contents-body {
        h1 {
          font-size: 20px;
        }
        .sub-list-box {
          b {
            font-size: 18px;
          }
          .sub-list {
            ::before {
              margin-right: 5px;
            }
          }
        }
        .cs-image-ul {
          display: flex;
          flex-wrap: wrap;
          max-width: 800px;
          margin: auto;
          .cs-image-li {
            width: calc(100% / 2 - 5px / 2);
            margin-right: 5px;
            margin-bottom: 5px;
            :nth-of-type(3n) {
              margin-right: 5px;
            }
            :nth-of-type(2n) {
              margin-right: 0px;
            }
          }
        }
        .cs-status-ul {
          display: block;
          .cs-status-li {
            margin-bottom: 20px;
            & + li {
              margin-left: unset;
            }
          }
        }
        .cs-info-image-area {
          display: block;
          margin-top: 50px;
          .cs-info-image-area-ul {
            .cs-info-image-area-li {
              h3 {
                font-size: 18px;
              }
              p {
                font-size: 14px;
                text-indent: unset;
              }
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = ({ t, setIsPopup, i18n }: any) => {
  return (
    <BusinessItMainStyle
      main={data.head.color.main}
      active={data.head.color.active}
      hover={data.head.color.hover}
      background={data.head.color.background}
    >
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-header">
            <h1>
              {t(data.head.description[0])}
              <br />
              {t(data.head.description[1])}
            </h1>
          </article>
          {data.item.map((res, idx) => {
            return (
              <article key={idx} className="main-contents-body">
                <h1>
                  <p>{t(res.title)}</p>
                </h1>
                <header className="sub-list-box">
                  <b>{t(res.titleDescription)}</b>
                  {res.subItemList.map((res2, idx2) => {
                    return (
                      i18n.language === "ko" && (
                        <span className="sub-list" key={idx2}>
                          {t(res2)}
                        </span>
                      )
                    );
                  })}
                </header>
                {idx === 2 && (
                  <article>
                    <h2 className="article-title">
                      <p>{t("cs_1_con_title")}</p>
                    </h2>
                    {i18n.language === "ko" ? (
                      <span className="cs-banner">{data.head.process}</span>
                    ) : (
                      <span className="cs-banner">{data.head.process2}</span>
                    )}
                  </article>
                )}
                <ul className="cs-image-ul">
                  {res.itemList.map((res2, idx) => {
                    return (
                      <li key={idx} className="cs-image-li">
                        <div className="contents-img">{res2.itemImg}</div>
                      </li>
                    );
                  })}
                </ul>
              </article>
            );
          })}
          <article className="main-contents-body">
            <h2 className="article-title">
              <p>{t("cs_4_con_2_title")}</p>
            </h2>
            <ul className="cs-status-ul">
              {data2.itemList.map((res, idx) => {
                return (
                  <li className="cs-status-li" key={idx}>
                    <div className="logo-box">{res.logo}</div>
                    <h3>{t(res.itemSubject)}</h3>
                  </li>
                );
              })}
            </ul>
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t("cs_4_con_3_title")}</p>
            </h1>
            <article className="cs-info-image-area">
              <span className="cs-info-image">{data2.infoImg}</span>
              <ul className="cs-info-image-area-ul">
                {data3.map((res, idx) => {
                  return (
                    <li key={idx} className="cs-info-image-area-li">
                      <h3>{t(res.name)}</h3>
                      <p>
                        <span>{t(res.address)}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </article>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessSupport = ({ t, i18n }: any) => {
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState("service");
  return (
    <>
      <BusinessSupportStyle className="no-select">
        <BusinessSupportMain t={t} />
        <BusinessItMain t={t} setIsPopup={setIsPopup} i18n={i18n} />
      </BusinessSupportStyle>
      <AnimatePresence>
        {isPopup && (
          <Modal isPopup={isPopup} setIsPopup={setIsPopup} type={type}></Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BusinessSupport;
