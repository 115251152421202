import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";

const CostumerData = {
    It: [
        {
            name: ["dell"],
            link: ["/business/it/solution/dell"],
            contents: [
                <img
                    alt="seagate-logo"
                    width={170}
                    style={{ fontSize: 0 }}
                    src="/icons/brand-dell.png"
                ></img>,
            ],
            email: "okdell@scgs.co.kr",
            phone: "02 - 000 - 0001",
        },
        {
            name: ["seagate", "benq", "tplink"],
            link: [
                "/business/it/solution/seagate",
                "/business/it/solution/benq",
                "/business/it/solution/tplink",
            ],
            contents: [
                <img
                    alt="seagate-logo"
                    height={"60px"}
                    width={"auto"}
                    style={{ fontSize: 0 }}
                    src="/icons/brand-seagate.png"
                ></img>,
                <img
                    alt="seagate-logo"
                    width={85}
                    style={{ fontSize: 0 }}
                    src="/icons/brand-benq2.png"
                ></img>,
                <Icons.BrandTpLink
                    style={{ width: "104px", height: "40px" }}
                />,
            ],
            email: "sdadmin@scgs.co.kr",
            phone: "02 - 000 - 0002",
        },
        {
            name: ["arista"],
            link: ["/business/it/solution/arista"],
            contents: [<Icons.BrandArista2 style={{ padding: "5px 0" }} />],
            email: "en@scgs.co.kr",
            phone: "02 - 000 - 0003",
        },
    ],
    Solar: [
        {
            name: ["solar"],
            link: [
                "/business/solar",
                "/business/solar",
                "/business/solar",
                "/business/solar",
                "/business/solar",
                "/business/solar",
                "/business/solar",
            ],
            contents: [
                <Icons.BrandJaSolar3 height={50} width={"100%"} />,
                <Icons.BrandJinkoSolar3 height={50} width={"100%"} />,
                <Icons.BrandTrinaSolar3 height={50} width={"100%"} />,
                <div
                    style={{
                        background: 'url("/icons/brand-hd.png")',
                        width: "100%",
                        backgroundSize: "200px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />,
                <Icons.BrandGrowatt height={30} width={"100%"} />,
                <Icons.BrandSolis height={45} width={"100%"} />,
                <Icons.BrandHyosung height={40} width={"100%"} />,
            ],
            email: "enterprise@scgs.co.kr",
            phone: "02 - 000 - 0004",
        },
    ],
    Service: [
        {
            name: ["managing", "support"],
            link: ["/business/managing", "/business/support"],
            brand: "service",
            contents: [""],
            email: "service@scgs.co.kr",
            phone: "02 - 000 - 0005",
        },
    ],
};

const titleData = [
    "IT Solution",
    "mainSolar",
    "mainManage2_1",
    "mainManage2_2",
];

const CostumerServiceStyle = styled.ul`
    .scroll {
        min-height: 600px;
    }
    .banner-image {
        background: url("/background/cs1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        .cs-banner {
            margin: auto;
            background: #ffffff99;
            width: 100%;
            padding: 50px 0;
            text-align: center;
            h1 {
                font-size: 60px;
                margin: auto;
                line-height: 1em;
            }
        }
    }
    .cs-table-box {
        max-width: 1200px;
        width: 95%;
        margin: 100px auto;
        color: #666;
    }
    .cs-table-head {
        text-align: center;
        display: flex;
        margin: auto;
        width: 100%;
        padding: 10px 0;
        background: #2d59a2;
        font-weight: bold;
        color: #fff;
        div {
            &.cs-table-division {
                width: 400px;
                min-width: 100px;
            }
        }
        h1 {
            display: flex;
            width: 900px;
            .cs-table-brand {
                width: 100%;
                min-width: 200px;
            }
            .cs-table-contact {
                width: 400px;
                min-width: 200px;
            }
        }
    }
    .cs-table {
        display: flex;
        margin: auto;
        width: 100%;
        :last-of-type {
            .brand-logo {
                :hover {
                    background-color: unset !important;
                }
            }
        }
        .cs-table-left {
            width: 400px;
            min-width: 200px;
            display: flex;
            border-bottom: 1px solid #eee;
            border-right: 1px solid #eee;
            &.hover-bg {
                transition: 0.3s;
                :hover {
                    background-color: #f4f4f4;
                }
            }
            h2 {
                margin: auto 0 auto 30px;
            }
        }
        .cs-table-right {
            display: flex;
            flex-direction: column;
            width: 900px;
            .cs-table-brand {
                width: 100%;
                min-width: 200px;
                display: flex;
                flex-direction: column;
                font-size: 0;
                justify-content: center;
                flex-wrap: wrap;
                .brand-logo {
                    height: 80px;
                    width: 100%;
                    transition: 0.3s;
                    display: flex;
                    :hover {
                        background-color: #f4f4f4;
                    }
                    span {
                        position: relative;
                        font-size: 20px;
                        &:last-of-type::after {
                            content: "/";
                            margin-left: 10px;
                        }
                    }
                    &:last-of-type span::after {
                        content: "";
                        margin-left: 0px;
                    }
                    a {
                        display: flex;
                        margin: auto;
                        width: 100%;
                        height: 100%;
                        align-self: center;
                        text-align: center;
                        align-content: center;
                        transition: 0.3s;
                        img {
                            margin: auto;
                        }
                        :hover {
                            transform: translate(-2px, -2px);
                        }
                    }
                    svg {
                        margin: auto;
                    }
                }
            }
            .cs-table-contact {
                width: 400px;
                min-width: 200px;
                border-left: 1px solid #eee;
                font-size: 15px;
                position: relative;
                transition: 0.3s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                    height: 40px;
                    width: 100%;
                    text-indent: 10px;
                    position: relative;
                    transition: 0.3s;
                    display: flex;
                    span {
                        margin: auto;
                    }
                    :hover {
                        background-color: #f4f4f4;
                    }
                }
                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        li {
            display: flex;
            min-height: 80px;
            & > div {
                border-bottom: 1px solid #eee;
            }
        }
    }
    .button {
        min-width: 120px;
        text-align: center;
        padding: 10px 0;
        color: #fff;
        transition: 0.3s;
        box-shadow: 2px 2px 5px 0 #00000022;
        border-radius: 3px;
        background: #003399;
        margin-top: 50px;
        font-size: 18px;
        &:hover {
            transition: 0.1s;
            background: #026;
        }
        &:active {
            transition: 0.1s;
            background: #001133;
            box-shadow: 0px 0px 2px 0 #00000022;
            transform: translate(1px, 1px);
        }
    }
    @media (max-width: 768px) {
        .scroll {
            min-height: 400px;
        }
        .banner-image {
            .cs-banner {
                h1 {
                    font-size: 30px;
                }
            }
        }
        .cs-table-box {
            overflow: auto;
        }
        .cs-table-head {
            div {
                &.cs-table-division:first-child {
                    width: 100px;
                    min-width: 100px;
                }
                &.cs-table-contact:last-child {
                    width: 100%;
                    min-width: unset;
                    display: none;
                }
            }
        }
        .cs-table {
            max-width: unset;
            .cs-table-left {
                width: 100px;
                min-width: 100px;
                h2 {
                    margin: auto 0 auto 10px;
                }
            }
            .cs-table-right {
                width: 900px;
                .cs-table-brand {
                    min-width: unset;
                    min-height: 40px;
                    div:nth-of-type(3) {
                        margin-left: unset;
                    }
                }
                .cs-table-contact {
                    width: 100%;
                    min-width: unset;
                    border-left: unset;
                    text-align: center;
                    height: 80px;
                    p {
                        margin: auto;
                    }
                }
            }
            li {
                flex-direction: column;
            }
        }
        .button {
            max-width: unset;
            width: 90%;
            min-width: unset;
            margin: 20px auto;
        }
    }
`;

const CostumerService = ({ t }: any) => {
    return (
        <>
            <CostumerServiceStyle className="no-select">
                <div className="scroll banner-image">
                    <motion.div
                        initial={{ transform: "scaleX(0)", opacity: 0 }}
                        animate={{ transform: "scaleX(1)", opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.7 }}
                        className="cs-banner"
                    >
                        <motion.h1
                            initial={{ translateY: "-50px", opacity: 0 }}
                            animate={{ translateY: "0", opacity: 1 }}
                            transition={{
                                delay: 0.3,
                                ease: "easeInOut",
                                duration: 1,
                            }}
                        >
                            {t("cs")}
                        </motion.h1>
                    </motion.div>
                </div>
                <div className="cs-table-box">
                    <div className="cs-table-head">
                        <div className="cs-table-division">
                            <p>{t("supportClass")}</p>
                        </div>
                        <h1>
                            <div className="cs-table-brand">
                                <p>{t("supportClass2")}</p>
                            </div>
                            <div className="cs-table-contact">
                                <p>
                                    {t("modalEmail")}
                                    {/* / {t("modalContact")} */}
                                </p>
                            </div>
                        </h1>
                    </div>
                    {Object.entries(CostumerData).map(([key, value], idx) => (
                        <div className="cs-table" key={key}>
                            <div
                                className={`cs-table-left ${
                                    idx === 2 && "hover-bg"
                                }`}
                            >
                                <h2>
                                    {idx === 2 ? (
                                        <>
                                            <Link
                                                to={
                                                    CostumerData.Service[0]
                                                        .link[0]
                                                }
                                            >
                                                {t(titleData[idx])}
                                            </Link>
                                            /
                                            <Link
                                                to={
                                                    CostumerData.Service[0]
                                                        .link[1]
                                                }
                                            >
                                                {t(titleData[idx + 1])}
                                            </Link>
                                        </>
                                    ) : (
                                        t(titleData[idx])
                                    )}
                                </h2>
                            </div>
                            <div className="cs-table-right">
                                {value.map((res, idx) => (
                                    <li key={idx}>
                                        <div className="cs-table-brand">
                                            {res?.contents !== undefined &&
                                                res?.contents.map(
                                                    (res2, idx2) => (
                                                        <div
                                                            className="brand-logo"
                                                            key={idx2}
                                                        >
                                                            {typeof res2 !==
                                                            "string" ? (
                                                                <Link
                                                                    to={`${res.link[idx2]}`}
                                                                    state={{
                                                                        type: res
                                                                            .name[
                                                                            idx2
                                                                        ],
                                                                    }}
                                                                >
                                                                    {res2}
                                                                </Link>
                                                            ) : (
                                                                <span>
                                                                    {t(res2)}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        <div className="cs-table-contact">
                                            <p>
                                                <span>{res?.email}</span>
                                                <a
                                                    target="_blank"
                                                    href={`mailto:${res?.email}`}
                                                    rel="noreferrer"
                                                ></a>
                                            </p>
                                            {/* <p>
                        <span>{res?.phone}</span>
                        <a
                          target="_blank"
                          href={`tel:${res?.phone}`}
                          rel="noreferrer"
                        ></a>
                      </p> */}
                                        </div>
                                    </li>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </CostumerServiceStyle>
        </>
    );
};

export default CostumerService;
