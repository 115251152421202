import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import API from "../api/api";
import { Link, useLocation } from "react-router-dom";
import Paging from "./Pagenation";
interface DataType {
  id: number;
  title: string;
  term: string;
  created_at: string;
  isActive: boolean;
  timestamp: string;
  caption: string;
  permalink: string;
  media_url: string;
}
const NewsStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;

const NewsMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/news-disclose.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
      p {
        max-width: 720px;
        margin: 30px auto auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const NewsMain = () => {
  return (
    <NewsMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            PR/IR
          </motion.h1>
        </motion.div>
      </div>
    </NewsMainStyle>
  );
};
const NewsContentsStyle = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    height: unset;
    padding: 100px 0;
    background: url("/intro_line_extend.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .inner-box {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      margin-top: 50px;
      font-size: 30px;
      position: relative;
      .board-more {
        font-size: 14px;
        transition: 0.3s;
        position: relative;
        position: absolute;
        line-height: 1.1em;
        padding: 5px 10px;
        right: 0;
        bottom: 0;
        display: flex;
        cursor: pointer;
        border-radius: 3px;
        ::before {
          content: "";
          position: absolute;
          transition: 0.3s;
          top: 0;
          left: 0;
          background-color: #2d59a2;
          width: 0%;
          height: 100%;
          z-index: -1;
        }
        svg {
          margin: auto 0;
          display: flex;
          path {
            transition: 0.3s;
            fill: #000;
          }
        }
        &:hover {
          color: #fff;
          ::before {
            transition: 0.1s;
            width: 100%;
          }
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    .contents-area {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: calc((100% - 40px) / 4);
        margin: 10px 5px;
        flex-direction: column;
        background: #eeeeeebb;
        position: relative;
        transition: 0.3s;
        font-size: 16px;
        :hover {
          transition: 0.1s;
          background-color: #2d59a2;
          color: #fff;
          .date {
            color: inherit;
          }
        }
        p {
          margin: 10px;
          padding: 0;
        }
        .date {
          text-align: left;
          color: #999;
        }
        .sns-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;
          display: -webkit-box;
          text-align: left;
          word-break: keep-all;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-bottom: 20px;
        }
        .media_url {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: #000;
          aspect-ratio: 1;
        }
        .no-image {
          background-size: 80%;
        }
      }
    }
    .news-board-notice {
      width: 100%;
      margin: 30px auto;
      min-height: 500px;
      font-size: 16px;
      background-color: #ffffff99;
      .sns-list {
        display: flex;
        height: 30px;
        margin-left: auto;
        width: fit-content;
        a {
          display: flex;
          position: relative;
          svg {
            margin: auto;
          }
        }
        a + a {
          margin-left: 10px;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        width: inherit;
        margin: 0 auto;
        .news-board-notice-head {
          display: flex;
          width: inherit;
          background: #2d59a2;
          color: #fff;
          font-weight: bold;
          p {
            font-size: 20px;
          }
          .title {
            text-align: center;
          }
        }
        li {
          display: flex;
          cursor: pointer;
          border-bottom: solid #ccc 1px;
          position: relative;
          &:hover {
            transition: 0.1s;
            background: #eee;
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        p {
          padding: 14px 0;
          line-height: 1em;
          text-align: center;
          &.number {
            min-width: 100px;
          }
          &.title {
            text-align: left;
            min-width: 200px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &.term {
            min-width: 200px;
          }
          &.date {
            min-width: 150px;
          }
        }
      }
    }
    .sns-style {
      position: relative;
      ul {
        flex-direction: row;
        flex-wrap: wrap;
        li {
          width: calc((100% - 40px) / 4);
          margin: 10px 5px;
          flex-direction: column;
          background: #eeeeeebb;
          border: none;
          :hover {
            transition: 0.1s;
            background-color: #2d59a2;
            color: #fff;
            .date {
              color: inherit;
            }
          }
          p {
            margin: 10px;
            padding: 0;
          }
          .date {
            text-align: left;
            color: #999;
          }
          .sns-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;
            display: -webkit-box;
            text-align: left;
            word-break: keep-all;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
          }
          .media_url {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #000;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
  .news-top-button {
    display: flex;
    border-bottom: solid 2px #2d59a2;
    .button {
      display: flex;
      align-items: center;
      text-align: center;
      transition: 0.3s;
      font-size: 18px;
      font-weight: bold;
      min-width: 100px;
      width: 100%;
      height: 60px;
      justify-content: center;
      position: relative;
      &.active {
        color: #fff;
        background: #2d59a2;
        transform: translate(1px, 1px);
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    .inner-box {
      h1 {
        margin-top: 20px;
        font-size: 20px;
      }
      .contents-area {
        li {
          width: calc((100% - 20px) / 2);
        }
      }
      .news-board-notice {
        margin: 10px auto;
        min-height: 428px;
        ul {
          .news-board-notice-head {
            p {
              font-size: 14px;
            }
          }
          p {
            padding: 12px 0;
            line-height: 1em;
            text-align: center;
            font-size: 14px;
            &.number {
              min-width: 40px;
            }
            &.title {
              text-align: left;
              min-width: 200px;
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            &.term {
              min-width: 200px;
            }
            &.date {
              min-width: 150px;
            }
          }
        }
      }
      .sns-style {
        ul {
          li {
            width: calc((100% - 20px) / 2);
          }
        }
      }
    }
    .news-top-button {
      .button {
        font-size: 16px;
        min-width: unset;
        height: 36px;
        &.active {
          color: #fff;
          background: #2d59a2;
          transform: translate(1px, 1px);
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
const NewsContents = ({ t }: any) => {
  const location = useLocation();
  const [mainData, setMainData] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [snsList, setSnsList] = useState([]);
  const [path, setPath] = useState(location.state?.type || 0);
  const [checkPath, setCheckPath] = useState("");
  const [pageNum, setPageNum] = useState(Number);
  const [lastPageNum, setLastPageNum] = useState();
  const [lastNewsList, setLastNewsList] = useState();
  const [lastDiscloseList, setLastDiscloseList] = useState();
  const up1 = useRef(null);
  const up2 = useRef(null);
  const up3 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    API.NEWS_LIST({
      boardName: "Board_News",
      setNewsData: setLastNewsList,
      take: 0,
    });
    API.NEWS_LIST({
      boardName: "Board_Announcement",
      setNewsData: setLastDiscloseList,
      take: 0,
    });
  }, [lastNewsList, lastDiscloseList]);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1, up2, up3].forEach(
      (ref) => ref.current && observer.observe(ref.current)
    );
  });
  useEffect(() => {
    path === 0 && API.MAIN_POST(setMainData);
    path === 0 && API.INSTAGRAM_GET_POSTS({ setDataList: setSnsList });
    // path === 2 && API.INSTAGRAM_GET_POSTS({ setDataList });
    path === 0 && setCheckPath("");
    path === 1 && API.MAIN_POST(setMainData);
    path === 1 && setCheckPath("Board_News");
    path === 2 && setCheckPath("");
    path === 2 && API.INSTAGRAM_GET_POSTS({ setDataList: setSnsList });
    path === 3 && setCheckPath("Board_Announcement");
    (checkPath === "Board_News" || checkPath === "Board_Announcement") &&
      API.BOARD_LIST({
        setDataList: setDataList,
        boardName: checkPath,
        setPageNum: setPageNum,
        setLastPageNum: setLastPageNum,
        pageNum: pageNum,
      });
  }, [path, checkPath, pageNum]);
  return (
    <NewsContentsStyle>
      <div className="scroll">
        <div className="inner-box" id="inner-box">
          <ul className="news-top-button up-soon" ref={up1}>
            <li
              className={`button ${path === 0 ? "active" : ""} pointer`}
              onClick={() => {
                setPath(0);
              }}
            >
              <p>최신</p>
            </li>
            <li
              className={`button ${path === 1 ? "active" : ""} pointer`}
              onClick={() => {
                setPath(1);
                setPageNum(0);
              }}
            >
              <p>NEWS</p>
            </li>
            <li
              className={`button ${path === 2 ? "active" : ""} pointer`}
              onClick={() => {
                setPath(2);
              }}
            >
              <p>SNS</p>
            </li>
            <li
              className={`button ${path === 3 ? "active" : ""} pointer`}
              onClick={() => {
                setPath(3);
                setPageNum(0);
              }}
            >
              <p>{t("newsAnnouncement")}</p>
            </li>
          </ul>
          {path === 0 && (
            <>
              <section>
                <h1>
                  <p>NEWS</p>
                  <p
                    onClick={() => {
                      setPath(1);
                    }}
                    className="board-more"
                  >
                    {t("more")} <Icons.ArrowRightW />
                  </p>
                </h1>
                <ul className="contents-area up-soon" ref={up2}>
                  {mainData?.map((res, idx) => {
                    if (idx < 4)
                      return (
                        <li key={idx}>
                          {res.imageList && res.imageList.length ? (
                            <p
                              className="media_url"
                              style={{
                                backgroundImage: `url(${res.imageList[0]})`,
                              }}
                            ></p>
                          ) : (
                            <p
                              className="no-image media_url"
                              style={{
                                backgroundColor: "#eee",
                                backgroundImage: `url(logo_sm_color.svg)`,
                              }}
                            ></p>
                          )}
                          <p className="sns-title">{res.title}</p>
                          <Link to={`news/${res.id}`}></Link>
                        </li>
                      );
                  })}
                </ul>
              </section>
              <section>
                <h1>
                  <p>SNS</p>
                  <p
                    onClick={() => {
                      setPath(2);
                    }}
                    className="board-more"
                  >
                    {t("more")} <Icons.ArrowRightW />
                  </p>
                </h1>
                <ul className="contents-area up-soon" ref={up3}>
                  {snsList?.map((res: DataType, idx) => {
                    if (idx < 4)
                      return (
                        <li key={idx}>
                          <p
                            className="media_url"
                            style={{
                              backgroundImage: `url(${res.media_url})`,
                            }}
                          ></p>
                          <p className="date">{`${
                            res.timestamp !== undefined &&
                            res.timestamp.slice(0, 10).replace(/-/g, ".")
                          }`}</p>
                          <p className="sns-title">{res.caption}</p>
                          <Link target="_blank" to={`${res.permalink}`}></Link>
                        </li>
                      );
                  })}
                </ul>
              </section>
            </>
          )}
          {path === 1 && (
            <>
              <section style={{ marginTop: "40px" }}>
                <ul className="contents-area">
                  {mainData?.map((res, idx) => {
                    if (idx < 4)
                      return (
                        <li key={idx}>
                          {res.imageList && res.imageList.length ? (
                            <p
                              className="media_url"
                              style={{
                                backgroundImage: `url(${res.imageList[0]})`,
                              }}
                            ></p>
                          ) : (
                            <p
                              className="no-image media_url"
                              style={{
                                backgroundColor: "#eee",
                                backgroundImage: `url(logo_sm_color.svg)`,
                              }}
                            ></p>
                          )}
                          <p className="sns-title">{res.title}</p>
                          <Link to={`news/${res.id}`}></Link>
                        </li>
                      );
                  })}
                </ul>
              </section>
              <div className="news-board-notice">
                <ul>
                  <div className="news-board-notice-head">
                    <p className="number">{t("newsNumber")}</p>
                    <p className="title">{t("newsTitle")}</p>
                  </div>
                  {dataList.map((res: DataType, idx) => {
                    return (
                      <li key={idx}>
                        <p className="number">
                          {lastNewsList && lastNewsList - pageNum * 10 - idx}
                        </p>
                        <p className="title">{res.title}</p>
                        <Link to={`news/${res.id}`}></Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="pagenation">
                {Paging({
                  lastPageNum: lastPageNum,
                  pageNum: pageNum,
                  setPageNum: setPageNum,
                })}
              </div>
            </>
          )}
          {path === 2 && (
            <>
              <div className="news-board-notice sns-style">
                <div className="sns-list">
                  <Link
                    target="_blank"
                    to={"https://www.facebook.com/SCGSolutions"}
                  >
                    <Icons.FaceBook />
                  </Link>
                  <Link target="_blank" to={"https://blog.naver.com/scgs10986"}>
                    <Icons.Blog />
                  </Link>
                </div>
                <ul>
                  {snsList.map((res: DataType, idx) => {
                    return (
                      <li key={idx}>
                        <p
                          className="media_url"
                          style={{ backgroundImage: `url(${res.media_url})` }}
                        ></p>
                        <p className="date">{`${res.timestamp
                          .slice(0, 10)
                          .replace(/-/g, ".")}`}</p>
                        <p className="sns-title">{res.caption}</p>
                        <Link target="_blank" to={`${res.permalink}`}></Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          {path === 3 && (
            <>
              <div style={{ marginTop: "50px" }} className="news-board-notice">
                <ul>
                  <div className="news-board-notice-head">
                    <p className="number">{t("newsNumber")}</p>
                    <p className="title">{t("newsTitle")}</p>
                  </div>
                  {dataList.map((res: DataType, idx) => {
                    return (
                      <li key={idx}>
                        <p className="number">
                          {lastDiscloseList &&
                            lastDiscloseList - pageNum * 10 - idx}
                        </p>
                        <p className="title">{res.title}</p>
                        <Link to={`disclose/${res.id}`}></Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="pagenation">
                {Paging({
                  lastPageNum: lastPageNum,
                  pageNum: pageNum,
                  setPageNum: setPageNum,
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </NewsContentsStyle>
  );
};

const News = ({ t }: any) => {
  return (
    <NewsStyle className="no-select">
      <NewsMain />
      <NewsContents t={t} />
    </NewsStyle>
  );
};

export default News;
